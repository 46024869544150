
import moment from 'moment';
import ScoreUtil from '../util/score-utils';
import CdnUtils from '../util/cdn-utils';

const baseXMLStorageUrl = 'https://assets.classkick.com/sticker-images/';

const StickerNames = [
  'apple',
  'pencil',
  'book',
  'magnifying-glass',
  'award',
  'trophy',
  'heart',
  'lightbulb',
  'flag',
  'pink-star',
  'gold-star',
  'green-star',
  'bee',
  'butterfly',
  'ladybug',
  'bookworm',
  'flower',
  'blossom',
  'owl',
  'bird',
  'chicken',
  'bear',
  'dog',
  'giraffe',
  'pig',
  'squirrel',
  'rabbit',
  'robot',
  'rocketship',
  'diamond'
];

export const StickerMap = {};

export const DefaultUserStickers = StickerNames.map((name) => {

  const defaultUrl = `${baseXMLStorageUrl}${name}.png`;

  StickerMap[defaultUrl] = `${defaultUrl}?alt=media`;

  return {
    name: name,
    url:  defaultUrl
  };
});

class StickerImageTypes {

  static get Custom() {
    return 'custom';
  }

  static get Stock() {
    return 'stock';
  }

  static get None() {
    return 'none';
  }

}

export class UserStickerTags {

  static get Score() {
    return 'score';
  }

  static get CustomImages() {
    return 'custom_images';
  }

}

export default class UserSticker {

  constructor(id, text, ownerId, imageUrl, created, tags) {
    const transformedUrl = CdnUtils.urlTransform(imageUrl);

    this._id = id;
    this._text = text;
    this._partialText = undefined;
    this._ownerId = ownerId;
    this._imageUrl = transformedUrl;
    this._created = created ? moment(created) : undefined;
    this._tags = tags;
  }

  static get MAX_SCORE() {
    return 100;
  }

  get BlankStickerUrl() {
    return UserSticker.BLANK_STICKER_URL;
  }

  static get BLANK_STICKER_URL() {
    return '../../../assets/images/blank_sticker.png';
  }

  static get DEFAULT_STICKER() {
    return new UserSticker(null, '', null, DefaultUserStickers[18].url, null, []);
  }

  /**
   * @returns {string}
   */
  get id() {
    return this._id;
  }

  /**
   * @returns {string}
   */
  get text() {
    return this._text;
  }

  /**
   * @param value {string}
   */
  set text(value) {
    this._text = value;
  }

  /**
   * @return {undefined}
   */
  get partialText() {
    if (angular.isUndefined(this._partialText)) {
      let text = angular.isString(this._text) ? this._text : '';
      this._partialText = text.length > 20 ? text.slice(0, 17) + '...' : text;
    }
    return this._partialText;
  }

  /**
   * @returns {string}
   */
  get ownerId() {
    return this._ownerId;
  }

  /**
   * @returns {string}
   */
  get imageUrl() {
    return this._imageUrl;
  }

  /**
   * @param value {string}
   */
  set imageUrl(value) {
    this._imageUrl = value;
  }

  get imageType() {
    if (!this._imageUrl) {
      return StickerImageTypes.None;
    }
    else if (StickerMap[this._imageUrl]) {
      return StickerImageTypes.Stock;
    }
    else {
      return StickerImageTypes.Custom;
    }
  }

  get imageName() {
    let sticker = DefaultUserStickers.find(({url}) => url === this._imageUrl);
    return sticker && sticker.name;
  }

  /**
   * @returns {moment}
   */
  get created() {
    return this._created;
  }

  /**
   * @returns {Array}
   */
  get tags() {
    return this._tags;
  }

  get score() {
    if (this._score) {
      return this._score;
    }
    let scoreTag = this.scoreTag;
    if (scoreTag && scoreTag.value) {
      scoreTag.value = ScoreUtil.formatScore(scoreTag.value);
    }
    return scoreTag && scoreTag.value;
  }

  get displayScore() {
    let displayScore = ScoreUtil.formatScore(this.score);
    if (angular.isNumber(displayScore) && displayScore > 0) {
      displayScore = '+' + displayScore;
    }

    return displayScore;
  }

  get scoreTag() {
    return this._tags.find((tag) => tag.type === UserStickerTags.Score);
  }

  get hasScoreTag() {
    return !!this.scoreTag;
  }

  removeScoreTag() {
    this._tags = this._tags.filter((tag) => tag.type !== UserStickerTags.Score);
  }

  set score(value) {
    if (this.hasScoreTag) {
      if (value === null) {
        this.removeScoreTag();
      } else {
        this._tags = this.tags.map((tag) => {
          return tag.type === UserStickerTags.Score ? {...tag, value: ScoreUtil.formatScore(value)} : tag;
        });
      }
    }
    else if (value != null) {
      this.tags.push({
        type: UserStickerTags.Score,
        value: value
      });
    }
    this._score = ScoreUtil.formatScore(value);
  }

  /**
   * @return {boolean}
   */
  get hasCustomImage() {
    return !StickerMap[this._imageUrl];
  }

}

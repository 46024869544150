'use strict';

import Assignment from '../../model/domain/assignment';
import Organization, { OrganizationTypes } from '../../model/domain/organization';
import { UserProperties, UserPreferences } from '../../model/domain/user';
import { AccountCreationStep } from '../../services/mixpanel/mixpanel.service';
import AccountNotification from '../../model/domain/account-notification';
import { LoginSources } from '../login/login.controller';

export default class SignupNameController {

  constructor($q, $stateParams, $timeout, $log, BreadcrumbService, AnalyticsService, CacheService, AuthService, StaticContentService,
    RosterService, AssignmentService, ToolbarService, NotificationService, ContractService, StorageService, IPStackService, OrganizationService, UserService, environment) {
    'ngInject';

    this.$q = $q;
    this.$stateParams = $stateParams;
    this.$timeout = $timeout;
    this.$log = $log;

    /** @type {BreadcrumbService} */
    this._breadcrumbService = BreadcrumbService;
    /** @type {AnalyticsService} */
    this._analyticsService = AnalyticsService;
    /** @type {CacheService} */
    this._cacheService = CacheService;
    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {StaticContentService} */
    this._staticContentService = StaticContentService;
    /** @type {RosterService} */
    this._rosterService = RosterService;
    /** @type {AssignmentService} */
    this._assignmentService = AssignmentService;
    /** @type {ToolbarService} */
    this._toolbarService = ToolbarService;
    /** @type {ContractService} */
    this._contractService = ContractService;
    /** @type {StorageService} */
    this._storageService = StorageService;
    /** @type {AnalyticsService} */
    this._analyticsService = AnalyticsService;
    /** @type {IPStackService} */
    this._ipStackService = IPStackService;
    /** @type {OrganizationService} */
    this._organizationService = OrganizationService;
    /** @type {NotificationService} */
    this._notificationService = NotificationService;
    /** @type {UserService} */
    this._userService = UserService;

    this._isLoading = false;
    this.prefix = null;
    this._displayName = '';
    this._firstName = this.$stateParams.firstName || '';
    this._lastName = this.$stateParams.lastName || '';
    this._schoolName = '';
    this._school = undefined;
    this.prefixOptions = UserProperties.PREFIX_OPTIONS;
    this._onboardingTest = undefined;
    this._ckRedirect = this._storageService.ckRedirect;
    this._hasSentAnalyticsPrefix = false;
    this._hasSentAnalyticsFirstName = false;
    this._hasSentAnalyticsLastName = false;
    this._lat = undefined;
    this._lng = undefined;
    this._grade = undefined;
    this._subject = undefined;
    this._gradeRanges = UserPreferences.GRADE_OPTIONS;

    this._environment = environment;
    this._tokenDeferred = this.$q.defer();
    this._token = this._tokenDeferred.promise;
    this._accountInitPromise = undefined;

    if (!this._authService.isLoggedIn) {
     this.goBack();
    }

    this._analyticsService.sendEvent({
      eventTag: 'page_viewed',
      properties: {
          location: 'teacherSignUpName'
      }
    });
  }

  /**
   * @return {boolean}
   */
  get isStudent() {
    return this.$stateParams.isStudent;
  }

  /**
   * @return {boolean}
   */
  get isTeacher() {
    return this.$stateParams.isTeacher;
  }

  /**
   * @return {boolean}
   */
  get isLoading() {
    return this._isLoading;
  }

  isOrganization(obj) {
    return obj instanceof Organization;
  }

  /**
   * @return {string}
   */
  get firstName() {
    return this._firstName;
  }

  /**
   * @param value {string}
   */
  set firstName(value) {
    this._firstName = value;
  }

  /**
   * @return {string}
   */
  get lastName() {
    return this._lastName;
  }

  /**
   * @param value {string}
   */
  set lastName(value) {
    this._lastName = value;
  }

  /**
   * @return {string}
   */
  get displayName() {
    return this._displayName;
  }

  /**
   * @param value {string}
   */
  set displayName(value) {
    this._displayName = value;
  }

  /**
   * @return {string}
   */
  get schoolName() {
    return this._schoolName;
  }

  /**
   * @param value {string}
   */
  set schoolName(value) {
    this._schoolName = value;
  }

  /**
   * @return {undefined|Organization}
   */
  get school() {
    return this._school;
  }

  /**
   * @param value {undefined|Organization}
   */
  set school(value) {
    this._school = value;
  }

  /**
   * @returns {string[]}
   */
  get gradeRanges() {
    return this._gradeRanges;
  }

  /**
   * @return {Array}
   */
  get grade() {
    return this._grade;
  }

  /**
   * @param value {string}
   */
  set grade(value) {
    this._grade = value;
  }

  /**
   * @return {string[]}
   */
  get subjectOptions() {
    return Assignment.Subjects;
  }

  /**
   * @return {string}
   */
  get subject() {
    return this._subject;
  }

  /**
   * @param value {string}
   */
  set subject(value) {
    this._subject = value;
  }

  /**
   * @param search {string} the search term
   * @param array {string[]} the original array of options
   * @returns {string[]}
   */
  autocompleteFilter(search, array) {
    if (!search) {
      return array;
    }
    return array.filter((value) => value.toLowerCase().includes(search.toLowerCase()));
  }

  /**
   * @param search {string}
   * @return {Promise<Organization[]>}
   */
  autocompleteSchool(search) {
    this._loadingAutocompleteSchool = true;
    return this._ipStackService.getLocation()
      .then((location) => {
        this._lat = location.lat;
        this._lng = location.lng;
        return this._organizationService.search(OrganizationTypes.School, location.lat, location.lng, search);
      })
      .then((organizations) => {
        this._loadingAutocompleteSchool = false;
        return [...organizations];
      });
  }

  get loadingAutocompleteSchool() {
    return this._loadingAutocompleteSchool;
  }

  onSelectPrefixChange() {
    if (!this._hasSentAnalyticsPrefix) {
      this._analyticsService.sendEvent({
        eventTag: 'teacherSignUpName:prefix_added'
      });
      this._hasSentAnalyticsPrefix = true;
    }
  }

  firstNameUpdated() {
    if (!this._hasSentAnalyticsFirstName) {
      if (this.isTeacher) {
        this._analyticsService.sendEvent({
          eventTag: 'teacherSignUpName:firstName_typed'
        });
        this._hasSentAnalyticsFirstName = true;
      } else {
        this._analyticsService.sendEvent({
          eventTag: 'studentSignUpName:firstName_typed'
        });
        this._hasSentAnalyticsFirstName = true;
      }
    }
  }

  lastNameUpdated() {
    if (!this._hasSentAnalyticsLastName) {
      if (this.isTeacher) {
        this._analyticsService.sendEvent({
          eventTag: 'teacherSignUpName:lastName_typed'
        });
        this._hasSentAnalyticsLastName = true;
      } else {
        this._analyticsService.sendEvent({
          eventTag: 'studentSignUpName:lastName_typed'
        });
        this._hasSentAnalyticsLastName = true;
      }
    }
  }

  saveStudentName() {
    this._analyticsService.sendEvent({
      eventTag: 'studentSignUpName:getStarted_clicked'
    });

    this._isLoading = true;
    this._analyticsService.createAccountProgress(AccountCreationStep.NAME);

    this._cacheService.getUser(false)
      .then((user) => {
        user.firstName = this._firstName;
        user.lastName = this._lastName;
        return this._cacheService.updateUser(user);
      })
      .then(() => {
        this.go();
      })
      .catch((err) => {
        this.$log.error(err);
        this.go();
      });
  }

  saveTeacher() {
    this._isLoading = true;

    this.$q.all({
      user: this._cacheService.getUser(false),
      school: this.school ? this.school : this._createOrganization()
    }).then(({ user, school }) => {
      const studentAge = this.convertGradeToStudentAge();
      user.displayName = this.displayName;
      user.subject = this.subject;
      user.studentAge = studentAge;

      const preferencesBody = {
        subject: this.subject,
        grade: this.grade,
        myStudentsCallMe: this.displayName
      };

      this.$q.all({
        updatedUser: this._cacheService.updateUser(user),
        token: this._organizationService.addSelf(school.id),
        preferences: this._userService.updateUserPreferences(user.id, preferencesBody),
      }).then(({ updatedUser, token, preferences }) => {
        this._tokenDeferred.resolve(token);

        this._accountInitPromise = this.$timeout(() => {
          this.goTeacher();
        }, this._environment.accountInitTimeout);

        // register for account init notification
        this._accountNotification = this._notificationService.getAccountNotification(this._authService.currentUserId);
        this._accountNotification.accountInitialized.subscribe(this._onAccountInit, this);
        this._accountNotification.start();

        this._cacheService.reset();
        this._analyticsService.createAccountProgress(AccountCreationStep.SCHOOL_NAME);
        this._analyticsService.createAccountProgress(AccountCreationStep.COMPLETE);

      }).catch((err) => {
        this._tokenDeferred.reject();
        if (!this._accountInitPromise) {
          this.go();
        }
        this.$log.error(err);
      });
    }).catch((err) => {
      this.$log.error(err);
    });
  }

  _createOrganization() {
    return this._organizationService.create('school', this.schoolName, this._lat, this._lng);
  }

  convertGradeToStudentAge() {
    switch (this.grade) {
      case UserPreferences.GRADE_OPTIONS[0]:
      case UserPreferences.GRADE_OPTIONS[1]:
        return UserProperties.STUDENT_AGE_OPTIONS[0];
      case UserPreferences.GRADE_OPTIONS[2]:
      case UserPreferences.GRADE_OPTIONS[3]:
      case UserPreferences.GRADE_OPTIONS[4]:
        return UserProperties.STUDENT_AGE_OPTIONS[1];
      case UserPreferences.GRADE_OPTIONS[5]:
      case UserPreferences.GRADE_OPTIONS[6]:
      case UserPreferences.GRADE_OPTIONS[7]:
        return UserProperties.STUDENT_AGE_OPTIONS[2];
      case UserPreferences.GRADE_OPTIONS[8]:
      case UserPreferences.GRADE_OPTIONS[9]:
      case UserPreferences.GRADE_OPTIONS[10]:
        return UserProperties.STUDENT_AGE_OPTIONS[3];
      case UserPreferences.GRADE_OPTIONS[11]:
      case UserPreferences.GRADE_OPTIONS[12]:
      case UserPreferences.GRADE_OPTIONS[13]:
        return UserProperties.STUDENT_AGE_OPTIONS[4];
      case UserPreferences.GRADE_OPTIONS[14]:
        return UserProperties.STUDENT_AGE_OPTIONS[5];
      default:
        return '';
    }
  }

  /**
 * Callback for a Firebase listener that listens for when the account is initialized.
 * @param value
 * @private
 */
  _onAccountInit(value) {
    if (value.change === AccountNotification.ACCOUNT_INITIALIZED) {
      this.$timeout.cancel(this._accountInitPromise);
      this._accountInitPromise = null;
      this.goTeacher();
    }
  }

  go() {
    if (this.isStudent) {
      this._analyticsService.createAccountProgress(AccountCreationStep.COMPLETE);
      this._breadcrumbService.clear();

      if (this._ckRedirect) {
        this._ckRedirect.redirect(this._breadcrumbService);
        this._storageService.ckRedirect = null;
      }
      else {
        this._breadcrumbService.go('root.account.home', {}, true);
      }
    }
    else {
      this._breadcrumbService.go('root.signup-school', {}, this.isStudent);
    }
  }

  goTeacher() {
    this._token
    .then((token) => {
      return this._authService.processTokenResult(token, this._authService.rememberMe);
    })
    .then(() => {
      this._onSuccess();
    })
    .catch(() => {
      this._onSuccess();
    });
  }

  _onSuccess() {
    this._toolbarService.reset();
    this._breadcrumbService.clear();
    this._isLoading = false;

    if (this._ckRedirect) {
      this._ckRedirect.redirect(this._breadcrumbService);
      this._storageService.ckRedirect = null;
    }
    else {
      this._breadcrumbService.go('root.account.nav.assignments', {
        source: LoginSources.SIGN_UP
      }, true);
    }
  }

  goBack() {
    let route = this.isStudent ? 'root.signup-student' : 'root.signup-teacher';
    this._breadcrumbService.goBack(route);
  }

}


'use strict';

import ConfirmDialogController from '../../components/confirm-dialog/confirm-dialog.controller';
import ErrorCodes from '../../model/domain/error-codes';
import LoadingDialogController from '../../components/loading-dialog/loading-dialog.controller';
import ErrorDialogController from '../../components/error-dialog/error-dialog.controller';
import TestData from '../../model/util/test-data';

/**
 * Admin view controller
 */
export default class AdminController {
  constructor($scope, $state, $timeout, $mdSidenav, $mdDialog, $mdToast, AuthService, CacheService, UserService, ContractService) {
    'ngInject';

    this.$scope = $scope;
    this.$state = $state;
    this.$timeout = $timeout;
    this.$mdSidenav = $mdSidenav;
    this.$mdDialog = $mdDialog;
    this.$mdToast = $mdToast;

    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {CacheService} */
    this._cacheService = CacheService;
    /** @type {UserService} */
    this._userService = UserService;
    /** @type {ContractService} */
    this._contractService = ContractService;

    this.userId = null;
    this.userIdToDelete = '';

    this._resetEmail = '';
    this._resetNewEmail = '';

    this._confirmDialog = ConfirmDialogController.show;
    this._loadingDialog = LoadingDialogController.show;
    this._errorDialog = ErrorDialogController.show;

    if (angular.isDefined(this.$state.params.hideNavText)) {
      this.hideNavText = this.$state.params.hideNavText;
    }

    this._contracts = null;
    this._cacheService.getContracts()
      .then((contracts) => {
        return this._contracts = contracts;
      });

    this._organizations = null;
    this._cacheService.getOrganizations()
      .then((organizations) => {
        return this._organizations = organizations;
      });
  }

  toggleSidenav() {
    this.$mdSidenav('nav').toggle();
  }

  get adminUserId() {
    if (this.signedInAsOther) {
      return this._authService.adminAuthData.email || this._authService.adminAuthData.username;
    }
    else {
      return this.currentUserId;
    }
  }

  get currentUserId() {
    return this._authService.authData && (this._authService.authData.email || this._authService.authData.username);
  }

  get signedInAsOther() {
    return !!this._authService.adminAuthData;
  }

  signInAsOther() {
    if (!this.userId) {
      return;
    }

    this.$scope.signInAsOtherForm.userId.$setValidity('fail', true);
    this._authService.authAsOther(this.userId)
      .then(() => {
        return this.reloadParent();
      })
      .catch(() => {
        this.$scope.signInAsOtherForm.userId.$setValidity('fail', false);
      });
  }

  signOutAsOther() {
    return this._authService.unauthAsOther()
      .then(() => {
        return this.reloadParent();
      });
  }

  reloadParent() {
    return this.$state.go(this.$state.current, {hideNavText: this.hideNavText}, {reload: 'root.account.nav'});
  }

  /**
   * References TeacherNavController.$scope.hideAdminTabText
   * @returns {boolean}
   */
  get hideNavText() {
    return this.$scope.$parent.hideAdminTabText;
  }

  /**
   * References TeacherNavController.$scope.hideAdminTabText
   * @param value {boolean}
   */
  set hideNavText(value) {
    this.$scope.$parent.hideAdminTabText = value;
  }

  deleteUser() {
    if (!this.userIdToDelete) {
      return;
    }

    this.$scope.deleteUserForm.userIdToDelete.$setValidity('fail', true);
    this.$scope.deleteUserForm.userIdToDelete.$setValidity('notfound', true);

    this._userService.find(this.userIdToDelete)
      .then((user) => {
        return this._confirmDialog(this.$mdDialog, 'Anna, are you sure you wanna do this? &#x1F605;').then(() => user);
      })
      .then((user) => {
        let promise = this._userService.delete(user.id).then(() => user);
        this._loadingDialog(this.$mdDialog, promise);
        return promise;
      })
      .then((user) => {
        let config = this.$mdToast.simple().textContent(`Deleted ${user.name}`).position('bottom right');
        this.$mdToast.show(config);
      })
      .catch((error) => {
        if (error && error.code === ErrorCodes.NOT_FOUND) {
          this.$scope.deleteUserForm.userIdToDelete.$setValidity('notfound', false);
        }
        else if (error) {
          this._errorDialog(this.$mdDialog, `${error.message}`);
        }
      });
  }

  deleteContract(contract) {
      this._confirmDialog(this.$mdDialog, `This will remove ALL teachers and students from ${contract.name} and delete the contract.\n\nIsla, are you sure you wanna do this? &#x1F605;`)
      .then(() => {
        this._authService.unauthAsOther();
      })
      .then(() => {
        return this._contractService.deleteContract(contract.id);
      })
      .then(() => {
        return this.reloadParent();
      })
      .catch((error) => {
        if (error && error.code === ErrorCodes.NOT_FOUND) {
          this.$scope.deleteUserForm.userIdToDelete.$setValidity('notfound', false);
        }
        else if (error) {
          this._errorDialog(this.$mdDialog, `${error.message}`);
        }
      });
  }

  get contracts() {
    return this._contracts;
  }

  get organizations() {
    return this._organizations;
  }

  resetUserPassword() {
    let password = TestData.generateRandomString(6, `${TestData.LowercaseLetters}${TestData.Digits}`);

    let promise = this._userService.changeAuthEmail(this._resetEmail.trim(), password, this._resetNewEmail.trim());

    this._loadingDialog(this.$mdDialog, promise);

    promise
      .then(() => {
        this._resetSuccess(this._resetEmail, password, this._resetNewEmail);
      })
      .catch((error) => {
        let buttons = [{ text: 'Yes', action: ErrorDialogController.CONFIRM }, { text: 'No', action: ErrorDialogController.CANCEL }];

        this._errorDialog(this.$mdDialog, 'Whoops, there was an error', `${error.message}. Would you like to reset their password anyway?`, buttons)
          .then(() => {
            this._userService.setPassword(this._resetEmail, password)
              .then(() => {
                this._resetSuccess(this._resetEmail, password, this._resetNewEmail);
              })
              .catch((error) => {
                this._errorDialog(this.$mdDialog, 'Whoops, there was an error', `${error.message}`);
              });
          });
      });
  }

  _resetSuccess(resetEmail, password, resetNewEmail) {
    let primarySuccessMessage = resetNewEmail ? `${resetEmail}'s account email has been changed to ${resetNewEmail}` : `${resetEmail}'s account has been updated`;
    let secondarySuccessMessage = `The new password is ${password}`;
    this._confirmDialog(this.$mdDialog, primarySuccessMessage, secondarySuccessMessage, true);
  }

  /**
   * @return {string}
   */
  get resetEmail() {
    return this._resetEmail;
  }

  /**
   * @param value {string}
   */
  set resetEmail(value) {
    this._resetEmail = value;
  }

  /**
   * @return {string}
   */
  get resetNewEmail() {
    return this._resetNewEmail;
  }

  /**
   * @param value {string}
   */
  set resetNewEmail(value) {
    this._resetNewEmail = value;
  }

}

'use strict';

import moment from 'moment';
import { maxBy } from 'lodash';
import { Locations, PaywallSources, SubscriptionFunnel } from '../../services/mixpanel/mixpanel.service';
import ViewHelpDialogController, { ViewHelps } from '../../components/view-help-dialog/view-help-dialog.controller';
import Contract from '../../model/domain/contract';
import UserProfileController from '../user-profile/user-profile.controller';
import { OrderManager } from '../../model/domain/order-manager';
import { OrderPurchasePeriod } from '../../model/domain/order-purchase-period';
import LoadingDialogController from '../../components/loading-dialog/loading-dialog.controller';
import FreeTrialDialogController from '../../components/free-trial-dialog/free-trial-dialog.controller';
import AdminInfoDialogController from '../../components/admin-info-dialog/admin-info-dialog.controller';
import ShareOrderDialogController from '../../components/share-order-dialog/share-order-dialog.controller';
import ReferralDialogController from '../../components/referral-dialog/referral-dialog.controller';
import IncentiveAssignmentLimitV1Controller
  from '../../components/incentive-assignment-limit-v1-dialog/incentive-assignment-limit-v1-dialog.controller';
import AdminInvitesTeachersDialogController from '../../components/admin-invites-teachers-dialog/admin-invites-teachers-dialog.controller';
import ProTrialOverlayController from '../../components/pro-trial-overlay/pro-trial-overlay.controller';
import ProTrialConversionOverlayController
  from '../../components/pro-trial-conversion-overlay/pro-trial-overlay-conversion.controller';
import ProTrialExtensionOverlayController
  from '../../components/pro-trial-extension-overlay/pro-trial-extension-conversion.controller';
import QuoteIframeDialogController from '../../components/quote-iframe-dialog/quote-iframe-dialog.controller';
import { ContractPlans } from '../../model/domain/contract';
import ProTrialConstants from '../../model/util/pro-trial-util';
import { ABTest } from '../../services/ab-test/ab-test-service';
import Order from '../../model/domain/order';
import LocationUtil from '../../model/util/location-util';
import StorageService from '../../services/storage/storage.service';
import VideoPlayerDialogController from '../../components/video-player-dialog/video-player-dialog.controller';
import { DEFAULT_ASSIGNMENT_LIMIT } from '../../model/domain/app-configuration';
import User, {UserRoles} from '../../model/domain/user';


const PAGES = {
  'root.account.nav.assignments': 'assignments',
  'root.account.nav.folder': 'assignments',
  'root.account.nav.rosters': 'rosters',
  'root.account.nav.roster': 'rosterEdit',
  'root.account.nav.hidden-rosters': 'hiddenRosters',
  'root.account.nav.profile': 'profile',
  'root.account.nav.admin': 'superUser',
  'root.account.nav.notifications': 'notifications',
  'root.account.nav.contract-users-no-id': 'adminDashboard',
  'root.account.nav.contract-users' : 'adminDashboard',
  'root.account.nav.metrics': 'metrics',
  'root.account.nav.metrics-no-contract-id': 'metrics',
  'root.account.nav.public-assignments-library' : 'assignmentLibrary',
  'root.account.nav.public-assignments-list' : 'publicAssignmentsList',
  'root.account.nav.public-assignment': 'publicAssignment',
  'root.account.nav.public-assignment-question' : 'publicAssignmentQuestion',
};

export default class NavController {

  constructor($scope, $state, $log, $filter, $stateParams, $mdSidenav, $mdDialog, $q, $timeout, $location, $window, environment,
              AuthService, CacheService, DelightedService, AnalyticsService, StaticContentService, LogRocketService, OrderService,
              FreeTrialService, OrganizationService, BreadcrumbService, StorageService, ContractService, RosterService, UserService) {
    'ngInject';

    this.$scope = $scope;
    this.$state = $state;
    this.$log = $log;
    this.$filter = $filter;
    this.$stateParams = $stateParams;
    this.$mdSidenav = $mdSidenav;
    this.$mdDialog = $mdDialog;
    this.$q = $q;
    this.$timeout = $timeout;
    this.$location = $location;
    this.$window = $window;
    this._environment = environment;

    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {CacheService} */
    this._cacheService = CacheService;
    /** @type {DelightedService} */
    this._delightedService = DelightedService;
    /** @type {AnalyticsService} */
    this._analyticsService = AnalyticsService;
    /** @type {StaticContentService} */
    this._staticContentService = StaticContentService;
    /** @type {LogRocketService} */
    this._logRocketService = LogRocketService;
    /** @type {OrderService} */
    this._orderService = OrderService;
    /** @type {FreeTrialService} */
    this._freeTrialService = FreeTrialService;
    /** @type {StorageService} */
    this._storageService = StorageService;
    /** @type {OrganizationService} */
    this._organizationService = OrganizationService;
    /** @type {BreadcrumbService} */
    this._breadcrumbService = BreadcrumbService;
    /** @type {ContractService} */
    this._contractService = ContractService;
    /** @type {RosterService} */
    this._rosterService = RosterService;
    /** @type {UserService} */
    this._userService = UserService;

    this._helpDialog = ViewHelpDialogController.show;
    this._loadingDialog = LoadingDialogController.show;
    this._adminInfoDialog = AdminInfoDialogController.show;
    this._shareOrderDialog = ShareOrderDialogController.show;
    this._referralDialog = ReferralDialogController.show;
    this._adminInvitesTeachersDialog = AdminInvitesTeachersDialogController.show;
    this._proTrialOverlay = ProTrialOverlayController.show;
    this._proTrialConversionOverlay = ProTrialConversionOverlayController.show;
    this._quoteIframeDialog = QuoteIframeDialogController.show;
    this._proTrialExtensionOverlay = ProTrialExtensionOverlayController.show;
    this._incentiveAssignmentLimitV1 = IncentiveAssignmentLimitV1Controller.show;
    this._videoPlayerDialog = VideoPlayerDialogController.show;

    /** @type {User} */
    this._user = null;
    /** @type {BuyerState} */
    this._buyerStateConfigs = null;
    /** @type {MessageSet} */
    this._messages = null;
    /** @type {Contract[]} */
    this.adminContracts = [];
    this.isAdmin = false;
    this.orders = [];

    this._userSchools = null;
    this.schoolsInDistrict = null;
    this.contracts = [];
    this._mainUserId = null;

    this._orderManager = new OrderManager($q, $log, $filter, $mdDialog, $location, AuthService, OrderService, OrganizationService,
      BreadcrumbService, CacheService, ContractService);

    this._unseenLatestFeatures = false;

    this.$scope.$on(NavController.UPDATE_LEFT_NAV, () => this._onUpdate());
    this.$scope.$on('$destroy', () => this._destroy());
    this.$scope.hideAdminTabText = false;

    this.canSeeFreeTrial = false;
    this.showStickerCopy = false;
    this._proTrialExtensionOverlaySeen = this._storageService.proTrialExtensionOverlaySeen;
    this._lastSeenProTrialOverlay = this._storageService.lastSeenProTrialConversionOverlay
      ? this._lastSeenProTrialOverlay = this._storageService.lastSeenProTrialConversionOverlay
      : undefined;
    this.renewalConversionBanner = this._storageService.lastSeenRenewalConversionBanner;

    /** @type Boolean
     * controls optional CSS class to
     * display/hide pro-cta detail visibility
     * **/
    this.areProDetailsExpanded = false;

    // sending these methods to pro trial conversion dialog bounded to nav controller
    this.handleAdminEmail = this.openEmailDialogToAdmin.bind(this);
    this.trialCountdown = this.getContractCountdown.bind(this);

    // Active School
    this.school = undefined;
    // Active Contract for active school
    this.activeContract = undefined;

    // Last school that had last active contract that has expired
    this.lastActiveSchool = undefined;
    // Last active contract that has expired
    this.lastActiveContract = undefined;
    this._classkickVideoUrl = 'https://www.youtube.com/watch?v=W3qtvE42O8k';

    // reset the "viewed" metric trackers
    this.hasSentGetProMetric = false;
    this.hasSentRenewalBannerMetric = false;

    this.init();
  }

  init() {
    //this is to check when the user is looking at their own roster items
    if (this.$stateParams && this.$stateParams.rosterId) {
      return this._rosterService.get(this.$stateParams.rosterId).then((roster) => {
        if (this._authService.coTeacherAuthData
          && this._authService.coTeacherAuthData.id !== this._authService.authData.id
          && this._authService.authData.id !== roster.ownerId
        ) {
          this._storageService.setUserInfoBackToCoTeacherAuth();
          this._authService.processTokenResult(this._authService.coTeacherAuthData.token, this._storageService.rememberMe)
            .then(() => {
              this._onUpdate();
            });
        } else {
          this._onUpdate();
        }
      });
    //reset auth back to co-teacher's original credentials
    } else if (this.isCoTeacherViewingNonCoTeachingPages()) {
      this._storageService.setUserInfoBackToCoTeacherAuth();
      this._authService.processTokenResult(this._authService.coTeacherAuthData.token, this._storageService.rememberMe)
        .then(() => {
          this._onUpdate();
        });
    } else {
      this._onUpdate();
    }
  }

  isCoTeacherViewingNonCoTeachingPages() {
    return this.isACoTeacher() && (
      this.viewingAssignments
      || this.viewingSharedAssignments
      || this.viewingTemplatesAndLibraries
      || this.viewingNotifications
      || this.viewingMetrics
      || this.viewingProfile
      || this.viewingAdmin
    );
  }

  _onUpdate() {
    if (this._authService.coTeacherAuthData) {
      /** @type {boolean} */
      this.isStudent = this._authService.coTeacherAuthData.isStudent;
      /** @type {boolean} */
      this.isTeacher = this._authService.coTeacherAuthData.isTeacher;
      /** @type {boolean} */
      this.isFreeUser = this._authService.coTeacherAuthData.isFree;
      /** @type {boolean} */
      this.isProUser = this._authService.coTeacherAuthData.isPro;
    } else if (this._authService.authData) {
      /** @type {boolean} */
      this.isStudent = this._authService.authData.isStudent;
      /** @type {boolean} */
      this.isTeacher = this._authService.authData.isTeacher;
      /** @type {boolean} */
      this.isFreeUser = this._authService.authData.isFree;
      /** @type {boolean} */
      this.isProUser = this._authService.authData.isPro;
    }

    this._mainUserId = this._authService.coTeacherAuthData && this._authService.coTeacherAuthData.id
    || this._authService.authData && this._authService.authData.id;

    this.$q
      .all({
        user: this._cacheService.getUser(),
        messages: this._cacheService.getMessagesForUser(),
        contracts: this._cacheService.getContracts(false, this.isTeacher),
        schools: this._cacheService.getSchools(false),
        assignmentCount: this.getAssignmentsTotal(),
        unverifiedOrganizations: this._cacheService.getUnverifiedOrganizations(true),
        stickerReferralSegment: this._cacheService.getTestSegment(ABTest.StickerReferrals),
        assignmentLimit20Segment: this._cacheService.getTestSegment(ABTest.AssignmentLimit20),
      })
      .then(({
               user,
               messages,
               contracts,
               schools,
               assignmentCount,
               unverifiedOrganizations, referralButtonSegment,
               stickerReferralSegment,
               assignmentLimit20Segment,
        }) => {
        this._user = user;
        this._messages = messages;
        this._messages.updated.subscribe(this._onMessagesUpdated, this);
        this.showStickerCopy = stickerReferralSegment;
        this.isAssignmentLimit20Segment = assignmentLimit20Segment;

        this._userService.getBuyerState(this._mainUserId).then((buyerStateConfigs) => {
          this._buyerStateConfigs = buyerStateConfigs;
          if (this._buyerStateConfigs && this._buyerStateConfigs.buyerState) {
            this._analyticsService.sendUniversalCtaEvent(PAGES[this.$state.current.name], this._mainUserId, this.buyerStateConfigs.buyerState, this.buyerStateConfigs.metric);
          }

          //universal CTA settings
          const progressBar = document.getElementsByTagName('progress')[0];
          if (progressBar && this.buyerStateConfigs) {
            progressBar.style.setProperty('--progress-color', this.buyerStateConfigs.ctaColor);
          }
        });

        this.contracts = contracts;
        this.activeContract = Contract.ActiveContract(contracts);
        this.adminContracts = contracts.filter((contract) => {
          return contract.isPro && this._authService.authData.isContractAdmin(contract.id);
        });
        this.isAdmin = this.adminContracts.length;
        this.assignmentCount = assignmentCount;
        this._unverifiedOrganizations = unverifiedOrganizations;

        // Temporary fix to hide renewal button from closed schools, ticket to remove: https://getclasskick.atlassian.net/browse/CKW-1797
        this.school = schools.find((school) => this.activeContract && school.contractId === this.activeContract.id);
        this.isSchoolClosed = this.school && !!this.school.closedEnd;

        this._userSchools = schools;

        this.isUnrenewedDistrictOrSchool = false;
        if (contracts.length && this.isTeacher) {
          this.isUnrenewedDistrictOrSchool = this.checkUnrenewedContracts(contracts);
          if (!this.school) {
            this.school = schools.find((school) => school.contractId === (this.unrenewedContract && this.unrenewedContract.id));
          }
        }

        this._analyticsService.setMixpanelProperty('isFreeTrial', !!this.isFreeTrial);

        // Delighted
        if (this._environment.delighted === 'true' && !!this._user && this.isTeacher) {
          // NPS
          this._delightedService.surveyNPS(
            this._user,
            this.isProUser
          );

          // Impact
          this._delightedService.surveyImpact(
            this._user,
            this.isProUser,
            this.school ? this.school : this._freeTrialService._selectOrganization(schools)
          );

          // Thumbs
          if (!this.isProDistrict) {
            this._delightedService.surveyUserReferral(
              this._user,
              this.isProUser,
              this.school ? this.school : this._freeTrialService._selectOrganization(schools)
            );
          }

          // Individual Pro Teacher Conversion
          if (this.isProClassroom) {
            this._delightedService.surveyProTeacherConversion(
              this._user,
              this.isProUser,
              this.school ? this.school : this._freeTrialService._selectOrganization(schools)
            );
          }
        }

        this.checkFreeTrial(schools);

        // If new user, show the incentive dialog
        if (this.shouldShowIncentiveDialog) {
          this._storageService.lastSeenIncentiveDialog = moment();
          this.openIncentiveAssignmentLimitV1();
        }

        if (this.school) {
          return this.$q.all({
            orders: this._orderService.getForOrganization(this.school.id),
            schoolsInDistrict: this.school.parentOrganizationId
              && this._cacheService.getChildOrganizationCount(this.school.parentOrganizationId)
          }).then(({orders, schoolsInDistrict}) => {
            this.orders = orders;
            this.schoolsInDistrict = schoolsInDistrict && schoolsInDistrict.count;
            if (this.isTeacher) {
              this.activeOrder = Order.findActiveOrder(this.orders);

              if (this.isFreeTrial) {
                this.daysLeft = Contract.contractCountdown(this.activeContract);
              }

              if (this.isUnrenewedDistrictOrSchool) {
                this.daysLeft = Contract.contractCountdown(this.unrenewedContract);
              }

              if (this.shouldDisplayProTrialConversionOverlay()) {
                this.showProTrialConversionOverlay();
              }

              if (this.shouldDisplayRenewalConversionBanner()) {
                this._storageService.lastSeenRenewalConversionBanner = {
                  showBanner: true,
                  time: moment()
                };
                this.renewalConversionBanner = this._storageService.lastSeenRenewalConversionBanner;
              }
            }
          });
        }

        this._resetLatestFeatures();
      });
  }

  get shouldShowIncentiveDialog() {
    return !this._storageService.lastSeenIncentiveDialog && this._user.justSignedUp;
  }

  get noAction() {
    return ProTrialConstants.NO_ACTION;
  }

  get proTrialOverlay() {
    return ProTrialConstants.PRO_TRIAL_OVERLAY;
  }

  get benefitsBanner() {
    return ProTrialConstants.BENEFITS_BANNER;
  }

  get referPeer() {
    return ProTrialConstants.REFER_PEER;
  }

  get nav() {
    return Locations.NAV;
  }

  get header() {
    return Locations.HEADER;
  }

  static get GREETING() {
    return 'Welcome back!';
  }

  get greeting() {
    if (this._user) {
      return this.isACoTeacher() && this._user.id !== this._authService.coTeacherAuthData.id ? `Co-teaching as ${this._user.name}` : `Hello, ${this._user.name}!` ;
    } else {
      return NavController.GREETING;
    }
  }

  get isNavOpen() {
    return this.$mdSidenav('nav').isOpen();
  }

  get viewAsUser () {
    return this._authService.viewAsUser;
  }

  /**
   * Toggles hide/show of pro details menu for the pro-cta
   */
  toggleProDetails() {
    this.areProDetailsExpanded = !this.areProDetailsExpanded;
    if (this.areProDetailsExpanded) {
      this._analyticsService.proTrialDetailsOpened();
    }
  }

  /**
   * Goes to the teacher's assignment list
   */
  goToAssignments() {
    this._analyticsService.sendEvent({
      eventTag: 'nav:asmt_clicked'
    });
    if (this.isNavOpen) {
      this.$mdSidenav('nav').close();
    }
    if (this.isACoTeacher()) {
      this._authService.clearData();
      this._storageService.setUserInfoBackToCoTeacherAuth();
      this._authService.processTokenResult(this._authService.coTeacherAuthData.token, this._storageService.rememberMe)
        .then(() => {
          this.$state.go('^.assignments', {source: ''}, {
            reload: 'root.account.nav'
          });
        });
    } else {
      this.$state.go('^.assignments', {source: ''});
    }
  }

  /**
   * Goes to the teacher's shared assignment list
   */
  goToSharedAssignments() {
    this._analyticsService.sendEvent({
      eventTag: 'nav:sharedAsmt_clicked'
    });
    if (this.isNavOpen) {
      this.$mdSidenav('nav').close();
    }
    if (this.isACoTeacher()) {
      this._authService.clearData();
      this._storageService.setUserInfoBackToCoTeacherAuth();
      this._authService.processTokenResult(this._authService.coTeacherAuthData.token, this._storageService.rememberMe)
        .then(() => {
          this.$state.go('root.account.nav.public-assignments-list', {
            userId: this._user.email || this._user.username
          }, {
            reload: 'root.account.nav'
          });
        });
    } else {
      this.$state.go('root.account.nav.public-assignments-list', {
        userId: this._user.email || this._user.username
      });
    }
  }

  /**
   * Goes to the public assignment library
   */
  goToPublicAssignmentLibrary() {
    this._analyticsService.sendEvent({
      eventTag: 'nav:templateAndLibraries_clicked'
    });
    if (this.isNavOpen) {
      this.$mdSidenav('nav').close();
    }
    this._breadcrumbService.go('root.account.nav.public-assignments-library', {});
  }

  /**
   * Goes to the teacher's rosters list
   */
  goToRosters() {
    this._analyticsService.sendEvent({
      eventTag: 'nav:rosters_clicked'
    });
    if (this.isNavOpen) {
      this.$mdSidenav('nav').close();
    }

    if (this.isACoTeacher()) {
      this._authService.clearData();
      this._storageService.setUserInfoBackToCoTeacherAuth();
      this._authService.processTokenResult(this._authService.coTeacherAuthData.token, this._storageService.rememberMe)
        .then(() => {
          this.$state.go('^.rosters', {}, {
            reload: 'root.account.nav'
          });
        });
    } else {
      this.$state.go('^.rosters');
    }
  }

  /**
   * Goes to the teacher's notifications list
   */
  goToNotifications() {
    this._analyticsService.sendEvent({
      eventTag: 'nav:notifications_clicked'
    });
    if (this.isNavOpen) {
      this.$mdSidenav('nav').close();
    }

    if (this.isACoTeacher()) {
      this._authService.clearData();
      this._storageService.setUserInfoBackToCoTeacherAuth();
      this._authService.processTokenResult(this._authService.coTeacherAuthData.token, this._storageService.rememberMe)
        .then(() => {
          this.$state.go('^.notifications', {}, {
            reload: 'root.account.nav'
          });
        });
    } else {
      this.$state.go('^.notifications');
    }
  }

  /**
   * Goes to the teacher's profile information
   */
  goToProfile() {
    this._analyticsService.sendEvent({
      eventTag: 'nav:profile_clicked'
    });

    if (this.isNavOpen) {
      this.$mdSidenav('nav').close();
    }

    if (this.isACoTeacher()) {
      this._authService.clearData();
      this._storageService.setUserInfoBackToCoTeacherAuth();
      this._authService.processTokenResult(this._authService.coTeacherAuthData.token, this._storageService.rememberMe)
        .then(() => {
          this.$state.go('^.profile', {
            tab: UserProfileController.BasicInfoTab
          }, {
            reload: 'root.account.nav'
          });
        });
    } else {
      this.$state.go('^.profile', {
        tab: UserProfileController.BasicInfoTab
      });
    }
  }

  goToAdmin() {
    this._analyticsService.sendEvent({
      eventTag: 'nav:superuser_clicked'
    });
    if (this.isNavOpen) {
      this.$mdSidenav('nav').close();
    }

    if (this.isACoTeacher()) {
      this._authService.clearData();
      this._storageService.setUserInfoBackToCoTeacherAuth();
      this._authService.processTokenResult(this._authService.coTeacherAuthData.token, this._storageService.rememberMe)
        .then(() => {
          this.$state.go('^.admin', {
            tab: UserProfileController.BasicInfoTab
          }, {
            reload: 'root.account.nav'
          });
        });
    } else {
      this.$state.go('^.admin');
    }
  }

  goToContractUsers(contractId) {
    this._analyticsService.sendEvent({
      eventTag: 'nav:manageUsers_clicked'
    });
    if (this.isNavOpen) {
      this.$mdSidenav('nav').close();
    }
    this.$state.go(
      '^.contract-users',
      {
        contractId: contractId
      });
  }

  goToMetrics(schoolId) {
    this._analyticsService.sendEvent({
      eventTag: 'nav:metrics_clicked',
      properties: {isAdmin: !!this.isAdmin}
    });
    if (this.isNavOpen) {
      this.$mdSidenav('nav').close();
    }

    if (this.isACoTeacher()) {
      this._authService.clearData();
      this._storageService.setUserInfoBackToCoTeacherAuth();
      this._authService.processTokenResult(this._authService.coTeacherAuthData.token, this._storageService.rememberMe)
        .then(() => {
          this.$state.go('^.metrics',
            {
              schoolId: schoolId
            }, {
              reload: 'root.account.nav'
            });
        });
    } else {
      this.$state.go('^.metrics', {schoolId: schoolId});
    }
  }

  goToStudentAssignments() {
    this._analyticsService.sendEvent({
      eventTag: 'nav:stuAsmt_clicked'
    });
    if (this.isNavOpen) {
      this.$mdSidenav('nav').close();
    }
    this.$state.go('^.student-assignments-list');
  }

  get unseenNotifications() {
    return this._messages ? this._messages.hasUnseenMessages : false;
  }

  get proUserType() {
    if (this.isProClassroom) {
      return 'Teacher';
    }
    else if (this.isProSchool) {
      return 'School';
    }
    else if (this.isProDistrict) {
      return 'District';
    } else {
      return '';
    }
  }

  get freeTrialLabel() {
    return this.isFreeTrial ? 'Free Trial' : '';
  }

  get isFreeTrial() {
    return this.activeContract && this.activeContract.isTrial && !this.purchasedProClassroomOnFreeTrialSchool();
  }

  get isFreeTrialVisible() {
    return this.canSeeFreeTrial;
  }

  get shouldShowProTrialOverlay() {
    return this.isFreeTrial && !this._storageService.proTrialOverlaySeen;
  }

  //TODO delete in the future if this will no longer be used
  showProTrialOverlay() {
    this._proTrialOverlay(this.$mdDialog)
    .then((buttonAction) => {
        this.proTrialOverlayAction(buttonAction);
    });
  }

  shouldShowProTrialExtensionOverlay() {
    const today = moment();
    //moment sets the time to Aug 01 2023 00:00:00 GMT-0400 and trial extensions ends 7/31
    const extensionDate = moment('2023-08-01');

    return this.isFreeTrial
      && !this._storageService.proTrialExtensionOverlaySeen
      && today <= extensionDate;
  }

  shouldDisplayProTrialConversionOverlay() {
    if (this.isFreeTrial && !this._storageService.sentAdminEmail) {
      if (!this._lastSeenProTrialOverlay) {
        return true;
      }
      const nextTimeToDisplay = moment(this._lastSeenProTrialOverlay).add(3, 'days');
      if (moment().isAfter(nextTimeToDisplay)) {
        return true;
      }
    }
    return false;
  }

  showProTrialConversionOverlay() {
    this._storageService.lastSeenProTrialConversionOverlay = moment();

    if (!this.activeOrder) {
      this.getOrCreateRenewalOrder()
        .then(() => {
          this._proTrialConversionOverlay(
            this.$mdDialog,
            this.handleAdminEmail,
            this.trialCountdown,
            this.activeContract,
            this.activeOrder && this.activeOrder.id
          );
      }).catch((error) => {
          this.$log.error(error);
      });
    }
    else {
      this._proTrialConversionOverlay(
        this.$mdDialog,
        this.handleAdminEmail,
        this.trialCountdown,
        this.activeContract,
        this.activeOrder && this.activeOrder.id
      );
    }
  }

  showProTrialExtensionOverlay() {
    this._storageService.proTrialExtensionOverlaySeen = true;

    this._proTrialExtensionOverlay(
      this.$mdDialog,
      this.school && this.school.name
    );
  }

  proTrialOverlayAction(action) {
    if (action === this.noAction) {
      this._analyticsService.proTrialOverlayDismissed();
    }
    if (action === this.benefitsBanner) {
      this._analyticsService.proTrialOverlayBenefits();
      this.isNavOpen ? null : this.$mdSidenav('nav').open();
      this.areProDetailsExpanded = true;
    }
    if (action === this.referPeer) {
      this.openReferAPeerDialogForFreeTrial(this.proTrialOverlay);
    }
  }

  checkFreeTrial(schools) {
    // This function is now a no-op.
    // Free Trial was removed with: https://getclasskick.atlassian.net/browse/TS-332
    // To get it back, see: https://github.com/classkick/classkick-web/pull/1403
    // (other vestiges of the free trial remain, because there's a chance it will return)

    // Revisit Banner CTA if we bringing free trial back.
  }

  freeTrialCount(today){
    const expiration = this.activeContract.expires;
    const daysLeft = expiration.diff(today, 'days') + 1;
    return daysLeft > 0 ? daysLeft : 0;
  }

  shareSchoolOrder() {
    this._analyticsService.getProFreeTrial();
    this._orderManager.shareSchoolOrder(this._user, this.school, this.orders, this.activeContract, Locations.NAV, this.isFreeTrial);
  }

  get isUpgradeEligible() {
    return this.isTeacher && (this.isFreeUser || this.isProClassroom || this.isUnrenewedDistrictOrSchool || this.isFreeTrial || this.purchasedProClassroomOnFreeTrialSchool());
  }

  get isContractAdmin() {
    return this.adminContracts && this.adminContracts.length > 0;
  }

  get isProClassroom() {
    return this.activeContract && this.activeContract.isProClassroom;
  }

  get isProSchool() {
    return this.activeContract && this.activeContract.isProSchool;
  }

  get isProDistrict() {
    return this.activeContract && this.activeContract.isProDistrict;
  }

  get isExpiringThisYear() {
    return this.activeContract && this.activeContract.expiresThisSchoolYear;
  }

  get isUnrenewedContractExpired() {
    return this.unrenewedContract && this.unrenewedContract.expires < moment();
  }

  get invincible() {
   return ContractPlans.PRO_DISTRICT;
  }

  get prometheus() {
    return ContractPlans.PRO_SCHOOL;
  }

  /**
   * This function is used to decide whether to show the renewal banner
   * prompting the user to contact their admin to get their Classkick
   * subscription renewed.
   *
   * As a side effect, if an unrenewed contract is found, it sets
   * this.unrenewedContract.
   *
   * The current rule is to show the unrenewed contract banner if the current
   * contract is expired, or:
   * all of the following are true:
   * - it is on or after March 1st in the calendar year
   * - there is a current subscription
   * - the subscription expires before July 31st
   * or,
   * all of the following are true:
   * - there is a current subscription
   * - the subscription expires within the next 6 months
   *
   * @param {*} contracts
   * @returns boolean
   */
  checkUnrenewedContracts(contracts) {
    return this.checkUnrenewedContractsAt(contracts, new Date());
  }

  /**
   * This function implements the logic of checkUnrenewedContracts for any
   * value of now, to enable testing with simple test cases.
   *
   * @param {*} contracts  The user's contracts.
   * @param {*} now        What time are we checking at? Will be new Date() in production.
   * @returns boolean
   */
  checkUnrenewedContractsAt(contracts, now) {

    let proContracts = contracts.filter((contract) => !contract.isTrial && (contract.plan === ContractPlans.PRO_SCHOOL || contract.plan === ContractPlans.PRO_DISTRICT));

    // If a school has never had a contract, we don't prompt for renewal
    if (proContracts.length === 0) {
      return false;
    }

    let latestProContract = maxBy(proContracts, (contract) => contract.expires);

    // Regardless of the date, we want to show the banner if the contract
    // expired or will expire within 3 months from now.
    let currentMonth = moment(now).month();
    let oneMonthAgo = moment(now).month(currentMonth - 1);
    let threeMonthsFromNow = moment(now).month(currentMonth + 3);
    if (latestProContract.expires >= oneMonthAgo && latestProContract.expires <= threeMonthsFromNow) {
      this.unrenewedContract = latestProContract;
      return true;
    }

    return false;
  }

  getAssignmentsTotal() {
    return this._cacheService.getAssignmentsForUser(false).then((assignmentsMap) => {
      const assignments = Array.from(assignmentsMap, (tuple) => tuple[1])
        .filter((assignment) => !assignment.isFolder);

      return assignments.length;
    });
  }

  get isRenewalTime() {
    let month = moment().month();
    return month >= 0 && month <= 6;
  }

  get renewalPromotionCopy() {
    return OrderPurchasePeriod.forStage(
      '',
      'Before prices increase July 1st!',
      'Before prices increase July 1st!'
    );
  }

  goToOrderPage(assignmentLimitHeader = false) {
    this._analyticsService.subscriptionFunnel(
      assignmentLimitHeader ? SubscriptionFunnel.ASSIGNMENT_LIMIT_HEADER : SubscriptionFunnel.LEARN_MORE,
      undefined,
      undefined,
       assignmentLimitHeader ? Locations.HEADER : PaywallSources.SIDEBAR_BUTTON
    );


    this._analyticsService.goToOrderPage(
        assignmentLimitHeader ? Locations.HEADER : Locations.NAV,
        (this.school && this.school.id) || this._userSchools[0].id,
        this.assignmentCount
    );
    this._staticContentService.goToOrdersPage(this._logRocketService.initialized);
  }

  openFreeTrialModal() {
    this._analyticsService.freeTrialOpened();
    FreeTrialDialogController.show(
      this.$mdDialog,
      {
        teacherName: this._user.name,
        teacherEmail: this._user.email,
        schools: this._userSchools
      }
    );
  }

  signOut() {
    this._authService.signOut();
  }

  get viewingAssignments() {
    return this.$state.is('root.account.nav.assignments') || this.$state.is('root.account.nav.folder');
  }

  get viewingRosters() {
    return this.$state.is('root.account.nav.rosters') || this.$state.is('root.account.nav.roster') || this.$state.is('root.account.nav.hidden-rosters') ;
  }

  get viewingNotifications() {
    return this.$state.is('root.account.nav.notifications');
  }

  get viewingProfile() {
    return this.$state.is('root.account.nav.profile');
  }

  get viewingAdmin() {
    return this.$state.is('root.account.nav.admin');
  }

  get viewingSharedAssignments() {
    return this.$state.is('root.account.nav.public-assignments-list');
  }

  get viewingTemplatesAndLibraries() {
    return this.$state.is('root.account.nav.public-assignments-library');
  }

  viewingContractUsers(contractId) {
    return this.$state.is('root.account.nav.contract-users') && this.$state.params.contractId === contractId;
  }

  viewingMetrics() {
    return this.$state.is('root.account.nav.metrics');
  }

  get viewingStudentAssignments() {
    return this.$state.is('root.account.nav.student-assignments-list');
  }

  _resetLatestFeatures() {
    let userHasNeverViewedLatestFeatures = angular.isUndefined(this._user.lastViewedLatestFeatures);
    let userHasNotViewedLatestFeaturesThisMonth = moment().format('MM-YYYY') !== moment(this._user.lastViewedLatestFeatures).format('MM-YYYY');
    this._unseenLatestFeatures =  userHasNeverViewedLatestFeatures || userHasNotViewedLatestFeaturesThisMonth;
  }

  openLatestFeaturesDialog() {
    this._analyticsService.latestFeaturesOpened();

    this._helpDialog(this.$mdDialog, ViewHelps.LatestFeatures);

    if (this._unseenLatestFeatures) {
      this._user.lastViewedLatestFeatures = moment().toISOString();
      this._cacheService.updateUser(this._user).then(() => {
        this._resetLatestFeatures();
      });
    }
  }

  openEmailDialogToAdminForRenewal(clickedFrom) {
    this._analyticsService.shareOrderDialogOpened(clickedFrom);

    let generatedOrderLink = undefined;

    // if the renewals have no price_paid then the value returned is negative (-10000)
    if (this.activeOrder && this.activeOrder.planPrice > 0) {
      generatedOrderLink = this.getOrderUrl();
    }

    let body = ShareOrderDialogController.formatOrderEmailForOrgRenewals(
      this._user.name,
      generatedOrderLink
    );

    if (!this.school && this.activeContract) {
      this.school = this._userSchools.find((school) => school.contractId === this.activeContract.id);
    }
    this.lastActiveContract = (!this.activeContract) ? Contract.LastActiveContract(this.contracts) : undefined;
    this.lastActiveSchool = (!this.school) ? this._userSchools.find((school) => this.lastActiveContract && school.contractId === this.lastActiveContract.id) : undefined;

    this.$q.all({
      activeSchoolAdmins: (this.school) ? this._organizationService.getMdrAdmins(this.school.id) : undefined,
      lastSchoolAdmins: (this.lastActiveSchool) ? this._organizationService.getMdrAdmins(this.lastActiveSchool.id) : undefined,
    })
      .then(({
               activeSchoolAdmins,
               lastSchoolAdmins
             }) => {
        this.mdrAdminEmails = (activeSchoolAdmins) ? activeSchoolAdmins : lastSchoolAdmins ? lastSchoolAdmins : {};
      })
      .finally(() => {
        this._shareOrderDialog(
          this.$mdDialog,
          this._user,
          this.activeOrder,
          this._user.email,
          undefined,
          ShareOrderDialogController.OrganizationRenewalTitle,
          body,
          this.activeContract || this.lastActiveContract || undefined,
          true,
          clickedFrom,
          this.mdrAdminEmails
        );
      });
  }

  generateOrderOrOpenEmailDialogToAdminForRenewals(clickedFrom){
    if (!this.activeOrder) {

      let promise = this.getOrCreateRenewalOrder()
        .then(() => {
          this.openEmailDialogToAdminForRenewal(clickedFrom);
      }).catch((error) => {
          this.$log.error(error);
        });

      return promise;
    }
     else {
      this.openEmailDialogToAdminForRenewal(clickedFrom);
    }
  }

  openEmailDialogToAdmin(clickedFrom) {
    this._analyticsService.shareOrderDialogOpened(clickedFrom);
    let currentOrderPrice = Order.getCurrentOrderPricePerUnit(this.activeOrder);
    let body = '';

    if (this.activeDistrictOrder) {
      body = currentOrderPrice < 1999 ?
        ShareOrderDialogController.formatOrderEmailForFreeTrialSchoolsWithDistrictQuote(
          this.activeOrder.id,
          this._user.name,
          this.$location,
          this.$filter('currency')(Order.getCurrentOrderPricePerUnit(this.activeOrder), '$', 0),
          this.schoolsInDistrict,
          this.activeDistrictOrder.id
        ) :
        ShareOrderDialogController.formatOrderEmailForFreeTrialSchoolsOver1999WithDistrictQuote(
          this.activeOrder.id,
          this._user.name,
          this.$location,
          this.$filter('currency')(Order.getCurrentOrderPricePerUnit(this.activeOrder), '$', 0),
          this.schoolsInDistrict,
          this.activeDistrictOrder.id
        );
    } else {
      body = currentOrderPrice < 1999 ?
        ShareOrderDialogController.formatOrderEmailForFreeTrialSchools(
          this.activeOrder.id,
          this._user.name,
          this.$location,
          this.$filter('currency')(Order.getCurrentOrderPricePerUnit(this.activeOrder), '$', 0),
        ) :
        ShareOrderDialogController.formatOrderEmailForFreeTrialSchoolsOver1999(
          this.activeOrder.id,
          this._user.name,
          this.$location,
          this.$filter('currency')(Order.getCurrentOrderPricePerUnit(this.activeOrder), '$', 0),
        );
    }

    if (!this.school && this.activeContract) {
      this.school = this._userSchools.find((school) => school.contractId === this.activeContract.id);
    }
    this.lastActiveContract = (!this.activeContract) ? Contract.LastActiveContract(this.contracts) : undefined;
    this.lastActiveSchool = (!this.school) ? this._userSchools.find((school) => this.lastActiveContract && school.contractId === this.lastActiveContract.id) : undefined;

    this.$q.all({
      activeSchoolAdmins: (this.school) ? this._organizationService.getMdrAdmins(this.school.id) : undefined,
      lastSchoolAdmins: (this.lastActiveSchool) ? this._organizationService.getMdrAdmins(this.lastActiveSchool.id) : undefined,
    })
    .then(({activeSchoolAdmins, lastSchoolAdmins }) => {
      this.mdrAdminEmails = (activeSchoolAdmins) ? activeSchoolAdmins : (lastSchoolAdmins) ? lastSchoolAdmins : {};
    })
    .finally(() => {
      this._shareOrderDialog(
        this.$mdDialog,
        this._user,
        this.activeOrder,
        this._user.email,
        undefined,
        ShareOrderDialogController.DefaultTitle,
        body,
        this.activeContract || this.lastActiveContract || undefined,
        false,
        clickedFrom,
        this.mdrAdminEmails
      );
    });
  }

  openIncentiveAssignmentLimitV1(clickedFrom) {
      this._incentiveAssignmentLimitV1(
        clickedFrom,
        this.$mdDialog,
        this._user,
        this._userSchools,
      );
  }

  openReferAPeerDialogForFreeTrial(clickedFrom) {
    this._analyticsService.proTrialReferralOpened(clickedFrom);
    // fake order id allows us to re-use the share order endpoint to send email
    let order = { id: '000000' };

    this._referralDialog(
      this.$mdDialog,
      this._user,
      order,
      this._user.email,
      undefined,
      ReferralDialogController.ProTrialReferralTitle,
      ReferralDialogController.formatProTrialMessage(this._user.name, this.school && this.school.name, this.$location),
      clickedFrom,
      true,
      false,
      this.school
    );
  }

  openReferAPeerDialogForProSchoolAndDistricts(clickedFrom) {
    this._analyticsService.proSchoolOrDistrictReferralOpened(clickedFrom);
    // fake order id allows us to re-use the share order endpoint to send email
    let order = { id: '000000' };

    this._referralDialog(
      this.$mdDialog,
      this._user,
      order,
      this._user.email,
      undefined,
      ReferralDialogController.ProSchoolAndDistrictReferralTitle,
      ReferralDialogController.formatProSchoolorDistrictReferralMessage(this._user.id, this._user.name, this.school && this.school.name, this.$location),
      clickedFrom,
      false,
      true,
      this.school
    );
  }

  openMaximizeUsageForProSchoolAndDistricts(clickedFrom) {
    this._analyticsService.remindInactiveTeachersDialogOpened(clickedFrom);

    this._adminInvitesTeachersDialog(this.$mdDialog, this.school.id, clickedFrom);
  }

  /**
   * @return {boolean}
   */
  get unseenLatestFeatures() {
    return this._unseenLatestFeatures;
  }

  /**
   * @return {boolean}
   */
  purchasedProClassroomOnFreeTrialSchool() {
    if (this.contracts && this.contracts.length) {
      const freeTrialContract = this.contracts.find((contract) => contract.isTrial && !contract.isExpired);
      const proClassroomContract = this.contracts.find((contract) => contract.isProClassroom && !contract.isExpired);

      if (freeTrialContract && proClassroomContract) {
        if (freeTrialContract.expires < proClassroomContract.expires) {
          this._storageService.removeFromLocalStorage(StorageService.lastSeenTrialConversionBanner);
          this._storageService.removeFromLocalStorage(StorageService.lastSeenRenewalConversionBanner);
          return true;
        }
      }
    }
    return false;
  }

  goToTeacherHelpCenter() {
    this._staticContentService.goToTeacherHelpCenter(true);
  }

  get userIsAdmin() {
    return this._authService.adminAuthData || (this._authService.authData && this._authService.authData.isAdmin);
  }

  _onMessagesUpdated() {
    this.$timeout(() => {});
  }

  _destroy() {
    if (this._messages) {
      this._messages.updated.unsubscribe(this._onMessagesUpdated, this);
    }
  }

  goToSalesBuddyV2(){
    this._staticContentService.goToSalesBuddyV2();
    this._analyticsService.sendEvent({
      eventTag: 'nav:proCTA_lmClicked'});
  }

  openAdminInfoDialog(){
    this._adminInfoDialog(this.$mdDialog, false);
  }

  goToSalesBuddyV2Wizard(){
    this._staticContentService.goToSalesBuddyV2Wizard();
    this._analyticsService.sendEvent({
      eventTag: 'nav:proCTA_wizardClicked'});
  }

  static get UPDATE_LEFT_NAV() { return 'UPDATE_LEFT_NAV'; }

  shouldDisplayRenewalConversionBanner() {
    let shouldDisplay = false;
    if (this.isTeacher
      && !(this.activeOrder && this.activeContract && this.activeContract.isProClassroom)
      && this.isUpgradeEligible
      && !this.isFreeTrial
      && this.isUnrenewedDistrictOrSchool
    ) {
      if (!this.renewalConversionBanner) {
        shouldDisplay = true;
      } else {
        const lastSeen = this.renewalConversionBanner.time;
        const showBanner = this.renewalConversionBanner.showBanner;
        const isSameDay = moment().isSame(lastSeen, 'day');

        if (!isSameDay) {
          shouldDisplay = true;
        } else {
          shouldDisplay = showBanner;
        }
      }
    }

    if (!this.hasSentRenewalBannerMetric && shouldDisplay) {
      this.hasSentRenewalBannerMetric = true;
      this._analyticsService.sendEvent({
        eventTag: 'header:renewalConversionBanner_viewed'
      });
    }

    return shouldDisplay;
  }

  getContractCountdown(){
    if (this.daysLeft > 1) {
      return `ends in ${this.daysLeft} days`;
    }
    if (this.daysLeft === 1) {
      return `ends in ${this.daysLeft} day`;
    }
    if (this.daysLeft === 0) {
      return 'ends today';
    }
    if (this.daysLeft < 0) {
      return `ended on ${this.unrenewedContract.expires.format('M/D/YYYY')}`;
    }
  }

  hideRenewalConversionBanner(){
    if (this.activeOrder) {
      this._analyticsService.closeRenewalConversionBanner(this.activeOrder.id, this.school.id);
    }
    else {
      this.getOrCreateRenewalOrder()
        .then(() => {
          this._analyticsService.closeRenewalConversionBanner(this.activeOrder.id, this.school.id);
      }).catch((error) => {
          this.$log.error(error);
      });
    }
    this._storageService.lastSeenRenewalConversionBanner = {
      showBanner: false,
      time: moment()
    };
    this.renewalConversionBanner = this._storageService.lastSeenRenewalConversionBanner;
  }

  getOrderUrl(){
    return `${LocationUtil.absRootUrl(this.$location)}/order/${this.activeOrder.id}`;
  }

  showIframe(){
    this._analyticsService.openQuotePageIframe(this.activeOrder.id);
    this._quoteIframeDialog(
      this.$mdDialog,
      this.getOrderUrl(),
      this.activeOrder.id
    );
  }

  goToOrderDetailsPage() {
    const orderId = this.activeOrder && this.activeOrder.id;
    this._analyticsService.goesToOrderDetailsPage(this.nav, orderId);
    this._staticContentService.goToOrder(orderId);
  }

  getOrCreateRenewalOrder() {
    let promise = this.$q.all({
      contracts: this._cacheService.getContracts(false, true, !this.isFreeTrial),
      schools: this._cacheService.getSchools(false)
    }).then(({schools, contracts}) => {
      this.activeContract = Contract.ActiveContract(contracts);
      let school = schools.find((school) => school.contractId === this.activeContract.id);
      return this.$q.all({
        school: school,
        orders: this._orderService.getForOrganization(school.id),
        renewal: !!school
      });
    }).catch((err) => {
      return this.$q.all({
        school: this.school,
        orders: this.orders,
        renewal: false
      });
    });

    this._loadingDialog(this.$mdDialog, promise);
    return promise.then((result) => {
      let contractHasPricePaid = false;
      if (this.activeContract && this.activeContract.pricePaid && this.activeContract.pricePaid > 0) {
        contractHasPricePaid = true;
      }
      return this._orderManager.generateSchoolOrder(
        this._user,
        result.school,
        result.orders,
        contractHasPricePaid ? this.activeContract : null,
        result.renewal)
        .then((order) => {
          return this.activeOrder = order;
      });
    });
  }

  createDistrictOrder() {
    if (!this.school && !this.school.parentOrganizationId || !this.schoolsInDistrict) {
      return;
    }
    return this.$q.all({
      district: this._organizationService.get(this.school.parentOrganizationId),
      orders: this.districtOrders,

    }).then(({district, orders}) => {
      const numberOfSchools = this.schoolsInDistrict === 1 ? 2 : this.schoolsInDistrict;
      return this._orderManager.getOrCreateDistrictOrder(
        this._user,
        numberOfSchools,
        district,
        orders
      );
    }).then((order) => {
      this._analyticsService.districtOrderGenerated(order.id, order.organizationId);
      return this.activeDistrictOrder = order;
    }).catch((err) => {
      this.$log.error(err);
    });
  }

  findDistrictOrderOrGenerateDistrictOrder() {
    if (this.school && this.school.parentOrganizationId && this.schoolsInDistrict) {
      this._orderService.getForOrganization(this.school.parentOrganizationId)
        .then((districtOrders) => {
          this.districtOrders = districtOrders;

          //check if a active district order already exists
          this.activeDistrictOrder = Order.findActiveOrder(districtOrders);

          //if no district other exist, generate a new district order
          if (!this.activeDistrictOrder) {
            this.createDistrictOrder();
          }
        });
    }
  }

  generateSchoolOrderAndDistrictOrder(){
    if (!this.school) {
      return;
    }
    this.getOrCreateRenewalOrder()
      .then(() => {
        this.findDistrictOrderOrGenerateDistrictOrder();
      }).catch((err) => {
      this.$log.error(err);
    });
  }

  showRedBanner(){
    return this.daysLeft <= 30;
  }

  showIncentiveAssignmentLimitV1Button() {
    return this.isFreeUser ;
  }

  isSameDay(actualDate, compareDate){
    return actualDate.isSame(compareDate, 'day');
  }

  reloadParent() {
    return this.$state.go(this.$state.current, {}, {
      reload: 'root.account.nav'
    });
  }

  updateSuperUserViewToUserView(){
    if (this._authService.viewAsUser === null) {
      this._authService.viewAsUser = false;
    } else {
      this._authService.viewAsUser = !this._authService.viewAsUser;
    }
    this.reloadParent();
  }

  isACoTeacher() {
    return this._authService.isACoTeacher();
  }

  get buyerStateConfigs(){
    return this._buyerStateConfigs;
  }

  _generateSchoolOrder() {
    const user = new User(
      '',
      'Auto Quote Created - In App',
      'no-reply+inapp@classkick.com',
      'Auto Quote Created',
      ' - In App',
      '',
      undefined,
      {},
      [UserRoles.TEACHER],
      [],
      ['password']
    );

    return this._organizationService.get(this._buyerStateConfigs.organizationId)
      .then((org) => {
        this._orderManager.generateSchoolOrder(
          user,
          org,
          [],
          null,
          this._buyerStateConfigs.renewal
        ).then((order) => {
          this._staticContentService.goToOrder(order.id);
        });
      })
      .catch((error) => {
        this.$log.error(error);
      });
  }

  openBuyerStateCta(){
    this._analyticsService.sendUniversalCtaEvent(PAGES[this.$state.current.name], this._mainUserId, this.buyerStateConfigs.buyerState, this.buyerStateConfigs.metric, true);

    if (this._buyerStateConfigs.cta === 'ck:smartQuote') {
      if (this._buyerStateConfigs.orderId) {
        return this._staticContentService.goToOrder(this._buyerStateConfigs.orderId);
      } else {
        return this._generateSchoolOrder();
      }
    } else {
      return this.$window.open(this._buyerStateConfigs.cta, '_blank', 'noreferrer');
    }
  }
}

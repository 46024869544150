
export function themeConfig ($mdThemingProvider) {
  'ngInject';

  $mdThemingProvider.definePalette('classkickPrimary', {
    '50': '#ffffff',
    '100': '#eff9e3',
    '200': '#d5eeb6',
    '300': '#b4e17c',
    '400': '#a5db63',
    '500': '#97d54a',
    '600': '#89cf31',
    '700': '#78b72b',
    '800': '#689e25',
    '900': '#58861f',
    'A100': '#ffffff',
    'A200': '#eff9e3',
    'A400': '#a5db63',
    'A700': '#78b72b',
    'contrastDefaultColor': 'light',
    'contrastDarkColors': '50 100 200 300 400 A100 A200 A400 A700',
    'contrastLightColors': undefined
  });

  $mdThemingProvider.definePalette('classkickWarn', {
    '50': '#ffffff',
    '100': '#ffffff',
    '200': '#fff1f1',
    '300': '#ffa9a9',
    '400': '#ff8b8b',
    '500': '#ff6c6c',
    '600': '#ff4d4d',
    '700': '#ff2f2f',
    '800': '#ff1010',
    '900': '#f10000',
    'A100': '#ffffff',
    'A200': '#ffffff',
    'A400': '#ff8b8b',
    'A700': '#ff2f2f',
    'contrastDefaultColor': 'light',
    'contrastDarkColors': '50 100 200 300 400 500 600 A100 A200 A400'
  });

  $mdThemingProvider.theme('default')
    .primaryPalette('classkickPrimary')
    .warnPalette('classkickWarn')
  ;

  $mdThemingProvider.theme('alert');
}

'use strict';
import moment from 'moment';
import AssignmentWorkService from '../../services/assignment-work/assignment-work.service';
import BootstrapService from '../../services/bootstrap/bootstrap.service';
import CacheService from '../../services/cache/cache.service';
import BreadcrumbService from '../../services/breadcrumb-service/breadcrumb.service';
import HelpRequestService from '../../services/help-request/help-request.service';
import MixpanelService from '../../services/mixpanel/mixpanel.service';
import NotificationService from '../../services/notification/notification.service';
import StaticContentService from '../../services/static/static-content.service';
import UserService from '../../services/user/user.service';
import Assignment from '../domain/assignment';
import AssignmentQuestion from '../domain/assignment-question';
import ElementList from '../domain/element-list';
import AssignmentWork from '../domain/assignment-work';
import Roster from '../domain/roster';
import ClassCode from '../domain/class-code';
import {UserRoles} from '../domain/user';
import {HelpRequestTypes} from '../domain/help-request';
import HelpRequest from '../domain/help-request';
import User from '../domain/user';
import HelpRequestSet from '../domain/help-request-set';
import HelpRequestNotification from '../domain/help-request-notification';
import JSEvent from './js-event';
import RosterNotification from '../domain/roster-notification';
import AssignmentStatusNotification from '../domain/assignment-notification';
import WorkQuestionSet from '../domain/work-question-set';
import AssignmentWorkNotification from '../domain/assignment-work-notification';
import UserStatusNotification from '../domain/user-status-notification';
import QuestionHelpers from '../domain/question-helpers';
import HelpersNotification from '../domain/helpers-notification';
import FirebaseCollection from '../firebase-mapping/firebase-collection';
import AuthService from '../../services/auth/auth.service';
import AssignmentTrackingService from '../../services/assignment-tracking/assignment-tracking.service';
import StorageService from '../../services/storage/storage.service';
import UserSticker from '../domain/user-sticker';
import ToolbarService from '../../services/toolbar/toolbar.service';
import AssignmentService from '../../services/assignment/assignment.service';
import FirebaseService from '../../services/firebase/firebase.service';
import FocusManagerService from '../../services/focus-manager/focus-manager.service';
import AssignmentExport from '../domain/assignment-export';
import MessageService from '../../services/message/message.service';
import Message from '../domain/message';
import RosterService from '../../services/roster/roster.service';
import CkRedirect from '../domain/ck-redirect';
import StickerService from '../../services/sticker/sticker.service';
import ContractService from '../../services/contract/contract.service';
import ClassCodeService from '../../services/class-code/class-code.service';
import HttpService from '../../services/http/http.service';
import StudentCacheService from '../../services/student-cache/student-cache.service';
import StaticService from '../../services/static/static.service';
import AssignmentRosterNotification from '../domain/assignment-roster-notification';
import ImageEditService from '../../services/image-edit/image-edit.service';
import CkImage from '../ui/elements/ckimage';
import ElementMetadata, {ElementIntents} from '../domain/element-metadata';
import Point from '../ui/point';
import Size from '../ui/size';
import Subscription, {SubscriptionStatus} from '../domain/subscription';
import Contract, {ContractPlans} from '../domain/contract';
import StripeService from '../../services/stripe/stripe.service';
import SubscriptionService from '../../services/subscription/subscription.service';
import ChatMessage, { ChatMessageTypes } from '../domain/chat-message';
import ChatMessageList from '../domain/chat-message-list';
import ChatMessageRead from '../domain/chat-message-read';
import CopyQuestionsRequest from '../domain/copy-questions-request';
import ABTestService from '../../services/ab-test/ab-test-service';
import GoogleFormService from '../../services/google-forms/google-form.service';
import StudentAssignmentService from '../../services/student-assignment/student-assignment.service';
import FillInTheBlankParent, { FillInTheBlankAnswer } from '../ui/elements/fill-in-the-blank-parent';
import AssignmentWorkQuestion from '../domain/assignment-work-question';
import GoogleClientService from '../../services/google/google-client.service';
import GoogleClassroomService from '../../services/google/google-classroom.service';
import CsvService from '../../services/csv/csv.service';
import DelightedService from '../../services/delighted/delighted.service';
import LogRocketService from '../../services/log-rocket/log-rocket.service';
import ExportService from '../../services/export/export.service';
import CleverService from '../../services/clever/clever.service';
import GoogleAnalyticsService from '../../services/google-analytics/google-analytics.service';
import FeedbackService from '../../services/feedback/feedback.service';
import OrganizationService from '../../services/organization/organization.service';
import PlatformHeaderService from '../../services/platform/platform-header.service';
import DatadogRumService from '../../services/datadog-rum/datadog-rum.service';
import OrderService from '../../services/order/order.service';
import CampaignService from '../../services/campaign/campaign.service';
import SidenavManager from '../../services/toolbar/sidenav-manager';
import ContributorHistoryService from '../../services/contributor-history/contributor-history.service';
import AssignmentRoster from '../domain/assignment-roster';
import AnswerExportService from '../../services/answer-export/answer-export.service';
import GradeExportService from '../../services/grade-export/grade-export.service';
import GooglePlacesService from '../../services/google-places/google-places.service';
import PendoService from '../../services/pendo/pendo.service';
import AnalyticsService from '../../services/analytics/analytics.service';
import AnalyticsMetaService from '../../services/analytics/analytics-meta.service';
import MdrTeacher from '../domain/mdr-teacher';
import BulkUpdateService from '../../services/bulk-update/bulk-update.service';
import SharedWorksService from '../../services/shared-works/shared-works.service';
import { SharedWorkUser } from '../domain/shared-work';
import CoTeacherService from '../../services/co-teacher/co-teacher.service';

/**
 * Creates a jest spy object with the supplied prototype's methods
 * @param prototype {prototype}
 * @param [accumulator] {string[]}
 * @returns {*}
 */
export const createSpyObj = (baseName, methodNames) => {
  let obj = {};

  for (let i = 0; i < methodNames.length; i++) {
    obj[methodNames[i]] = jest.fn();
  }

  return obj;
};

export function createMock(prototype, accumulator = []) {
  const descriptors = Object.getOwnPropertyDescriptors(prototype);
  for (let key in descriptors) {
    if (angular.isFunction(descriptors[key].value)) {
      accumulator.push(key);
    }
  }
  const parent = Object.getPrototypeOf(prototype);
  if (parent && parent !== Object.prototype) {
    return createMock(parent, accumulator);
  }
  else {
    return createSpyObj(prototype.constructor.name, accumulator);
  }
}

/**
 * Generates jasmine spy (mock) instances of the app's services
 */
export class MockServices {
  constructor() {
  }

  /**
   * @returns {$location}
   */
  get $location() {
    return {
      'absUrl': jest.fn(),
      'url': jest.fn()
    };
  }

  /**
   * @return {$log}
   */
  get $log() {
    return {
      'log': jest.fn(),
      'warn': jest.fn(),
      'error': jest.fn()
    };
  }

  /**
   * @return {$state}
   */
  get $state() {
    return {
      'go': jest.fn(),
      'is': jest.fn()
    };
  }

  /**
   * @return {$mdDialog}
   */
  get $mdDialog() {
    return {
      'show': jest.fn()
    };
  }

  /**
   * @returns {AuthService}
   * @constructor
   */
  get AuthService() {
    const result = createMock(AuthService.prototype);
    result.userAuthenticated = new JSEvent(result);
    result.dataCleared = new JSEvent(result);
    return result;
  }

  /**
   * @returns {AnswerExportService}
   */
  get AnswerExportService() {
    return createMock(AnswerExportService.prototype);
  }

  /**
   * @returns {AssignmentService}
   * @constructor
   */
  get AssignmentService() {
    return createMock(AssignmentService.prototype);
  }


  /**
   * @returns {AssignmentTrackingService}
   * @constructor
   */
  get AssignmentTrackingService() {
    const result = createMock(AssignmentTrackingService.prototype);
    result.undoStackUpdated = new JSEvent(result);
    return result;
  }

  /**
   * @returns {AssignmentWorkService}
   */
  get AssignmentWorkService() {
    return createMock(AssignmentWorkService.prototype);
  }

  /**
   * @returns {BootstrapService}
   */
  get BootstrapService() {
    return createMock(BootstrapService.prototype);
  }

  /**
   * @returns {BreadcrumbService}
   */
  get BreadcrumbService() {
    return createMock(BreadcrumbService.prototype);
  }

  /**
   * @returns {CacheService}
   */
  get CacheService() {
    return createMock(CacheService.prototype);
  }

  /**
   * @returns {SharedWorksService}
   */
  get SharedWorksService() {
    return createMock(SharedWorksService.prototype);
  }

  /**
   * @returns {CoTeacherService}
   */
  get CoTeacherService() {
    return createMock(CoTeacherService.prototype);
  }

  /**
 * @returns {SidenavManager}
 */
    get SidenavManager() {
    return createMock(SidenavManager.prototype);
  }

  /**
   * @returns {CampaignService}
   */
  get CampaignService() {
    return createMock(CampaignService.prototype);
  }

  /**
   * @returns {OrderService}
   */
  get OrderService() {
    return createMock(OrderService.prototype);
  }

  /**
   * @returns {ClassCodeService}
   */
  get ClassCodeService() {
    return createMock(ClassCodeService.prototype);
  }

  /**
   * @return {CleverService}
   */
  get CleverService() {
    return createMock(CleverService.prototype);
  }

  /**
   * @returns {ContractService}
   */
  get ContractService() {
    return createMock(ContractService.prototype);
  }

  /**
   * @return {CsvService}
   */
  get CsvService() {
    return createMock(CsvService.prototype);
  }

  /**
   * @return {DelightedService}
   */
  get DelightedService() {
    return createMock(DelightedService.prototype);
  }

  /**
   * @return {ExportService}
   */
  get ExportService() {
    return createMock(ExportService.prototype);
  }

  /**
   * @return {FeedbackService}
   */
  get FeedbackService() {
    return createMock(FeedbackService.prototype);
  }

  /**
   * @returns {FirebaseService}
   */
  get FirebaseService() {
    return createMock(FirebaseService.prototype);
  }

  /**
   * @returns {FocusManagerService}
   */
  get FocusManagerService() {
    return createMock(FocusManagerService.prototype);
  }

  /**
   * @return {GradeExportService}
   */
  get GradeExportService() {
    return createMock(GradeExportService.prototype);
  }

  /**
   * @return {GoogleAnalyticsService}
   */
  get GoogleAnalyticsService() {
    return createMock(GoogleAnalyticsService.prototype);
  }

  /**
   * @returns {GoogleFormService}
   */
  get GoogleFormService() {
    return createMock(GoogleFormService.prototype);
  }

  /**
   * @returns {GoogleClassroomService}
   */
  get GoogleClassroomService() {
    return createMock(GoogleClassroomService.prototype);
  }

  /**
   * @returns {GooglePlacesService}
   */
  get GooglePlacesService() {
    return createMock(GooglePlacesService.prototype);
  }

  /**
   * @returns {GoogleClientService}
   */
  get GoogleClientService() {
    return createMock(GoogleClientService.prototype);
  }

  /**
   * @returns {HelpRequestService}
   */
  get HelpRequestService() {
    return createMock(HelpRequestService.prototype);
  }

  /**
   * @returns {HttpService}
   */
  get HttpService() {
    return createMock(HttpService.prototype);
  }

  /**
   * @returns {ImageEditService}
   */
  get ImageEditService() {
    return createMock(ImageEditService.prototype);
  }

  /**
   * @return {LogRocketService}
   */
  get LogRocketService() {
    return createMock(LogRocketService.prototype);
  }

  /**
   * @returns {MediaService}
   */
  get MediaService() {
    return createMock(MixpanelService.prototype);
  }

  /**
   * @return {MessageService}
   */
  get MessageService() {
    return createMock(MessageService.prototype);
  }

  /**
   * @returns {MixpanelService}
   */
  get MixpanelService() {
    return createMock(MixpanelService.prototype);
  }

  /**
   * @returns {NotificationService}
   */
  get NotificationService() {
    return createMock(NotificationService.prototype);
  }

  /**
   * @returns {OrganizationService}
   */
  get OrganizationService() {
    return createMock(OrganizationService.prototype);
  }

  /**
   * @return {AnalyticsService}
   */
  get AnalyticsService() {
    return createMock(AnalyticsService.prototype);
  }

  /**
   * @return {AnalyticsMetaService}
   */
  get AnalyticsMetaService() {
    return createMock(AnalyticsMetaService.prototype);
  }

  /**
   * @return {RosterService}
   */
  get RosterService() {
    return createMock(RosterService.prototype);
  }

  /**
   * @param [$timeout]
   * @return {StaticService}
   */
  mockStaticService($timeout) {
    const result =  createMock(StaticService.prototype);
    result.ImageEditService = this.ImageEditService;

    if ($timeout) {
      result.$timeout = $timeout;
    }

    StaticService._testInstance = result;

    return result;
  }

  /**
   * @returns {StaticContentService}
   */
  get StaticContentService() {
    return createMock(StaticContentService.prototype);
  }

  /**
   * @returns {StickerService}
   */
  get StickerService() {
    return createMock(StickerService.prototype);
  }

  /**
   * @returns {StorageService}
   */
  get StorageService() {
    const result = createMock(StorageService.prototype);
    result.onRemoteLogOut = new JSEvent(result);
    return result;
  }

  /**
   * @returns {PendoService}
   */
  get PendoService() {
    return createMock(PendoService.prototype);
  }

  /**
   * @return {StripeService}
   */
  get StripeService() {
    return createMock(StripeService.prototype);
  }

  /**
   * @return {StudentAssignmentService}
   */
  get StudentAssignmentService() {
    return createMock(StudentAssignmentService.prototype);
  }

  /**
   * @returns {StudentCacheService}
   */
  get StudentCacheService() {
    let service = createMock(StudentCacheService.prototype);
    service.userRosterUpdated = new JSEvent(service);
    return service;
  }

  /**
   * @return {SubscriptionService}
   */
  get SubscriptionService() {
    return createMock(SubscriptionService.prototype);
  }

  /**
   * @return {ContributorHistoryService}
   */
  get ContributorHistoryService() {
    return createMock(ContributorHistoryService.prototype);
  }

  /**
   * @return {BulkUpdateService}
   */
  get BulkUpdateService() {
    return createMock(BulkUpdateService.prototype);
  }

  /**
   * @returns {ToolbarService}
   */
  get ToolbarService() {
    return createMock(ToolbarService.prototype);
  }

  /**
   * @returns {DatadogRumService}
   */
  get DatadogRumService() {
    return createMock(DatadogRumService.prototype);
  }


  /**
   * @returns {UserService}
   */
  get UserService() {
    return createMock(UserService.prototype);
  }

  /**
   * @returns {ABTestService}
   */
  get ABTestService() {
    return createMock(ABTestService.prototype);
  }

  get PlatformHeaderService() {
    let service =  createMock(PlatformHeaderService.prototype);
    return service;
  }
}

const _mockServices = new MockServices();

/**
 * Contains definitions for testing data structures and utilities
 */
export default class TestData {
  constructor() {
  }

  //------------------ Utility methods ---------------------------

  static mockApp() {
    angular.mock.module('classkickApp',
      {
        BootstrapService: TestData.mockServices.BootstrapService,
        gapi: {
          'load': jest.fn()
        }
      }
    );
  }

  /**
   * @param prototype {T}
   * @returns {T} Jasmine mock object containing all methods of prototype
   * @template T
   */
  static createMock(prototype) {
    return createMock(prototype);
  }

  /**
   * Returns a blank array of the given length
   * @param length
   * @returns {Array}
   */
  static sequence(length) {
    return Array.from(new Array(length));
  }

  /**
   * Generates a random integer between min (inclusive) and max (exclusive)
   * @param min
   * @param max
   * @returns {int}
   */
  static randomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min;
  }

  /**
   * @returns {string}
   */
  static get randomString() {
    return TestData.generateRandomString(12);
  }

  /**
   * @param num {number}
   * @param [chars] {string}
   * @return {string}
   */
  static generateRandomString(num, chars) {
    let text = '';
    let possible = chars || `${TestData.UppercaseLetters}${TestData.LowercaseLetters}${TestData.Digits}`;

    for (let i = 0; i < num; i++ ) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  static get UppercaseLetters() {
    return 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  }

  static get LowercaseLetters() {
    return 'abcdefghijklmnopqrstuvwxyz';
  }

  static get Digits() {
    return '0123456789';
  }

  /**
   * Returns a random element from the array, optionally avoiding collisions with another specific element of the array
   *
   * @template T
   * @param array {T[]}
   * @param [except] {T}
   * @returns {T}
   */
  static randomElement(array, except) {
    let choice = undefined;
    if (array.length === 0) {
      return choice;
    }

    do {
      choice = array[TestData.randomInt(0, array.length)];
    } while (array.length > 1 && except && except === choice);

    return choice;
  }

  //------------------- Data generation methods ---------------------

  /**
   * @returns {MockServices}
   */
  static get mockServices() {
    return _mockServices;
  }

  /**
   * @param [assignmentId] {string}
   * @param [questionCount] {int}
   * @param [rosters] {string[]}
   * @param [name] {string}
   * @param [tags] {Array}
   * @returns {Assignment}
   */
  static assignment(assignmentId, questionCount, rosters, name, tags) {
    return new Assignment(
      assignmentId || TestData.randomString,
      name || TestData.randomString,
      TestData.randomString,
      TestData.sequence(questionCount || TestData.randomInt(2, 5)).map(() => TestData.assignmentQuestion()),
      TestData.randomString,
      undefined,
      moment(),
      rosters || TestData.sequence(TestData.randomInt(2, 5)).map(() => TestData.randomString),
      tags || [],
      null,
      null
    );
  }

  /**
   * @param [assignment] {Assignment}
   * @param [workId] {string}
   * @param [userId] {string}
   * @param [rosterId] {string}
   * @param [workQuestions] {Map<string, AssignmentWorkQuestion>}
   * @param [modified] {object}
   * @returns {AssignmentWork}
   */
  static assignmentWork(assignment, workId, userId, rosterId, workQuestions, modified) {
    const rootAssignment = assignment || TestData.assignment();
    return new AssignmentWork(
      rootAssignment,
      workId || TestData.randomString,
      userId || TestData.randomString,
      rosterId || TestData.randomString,
      workQuestions || new Map(rootAssignment.questions.map((value) => [value.id, TestData.assignmentWorkQuestion()])),
      modified || moment()
    );
  }

  /**
   * @param [questionId] {string}
   * @param [points] {int}
   * @returns {AssignmentQuestion}
   */
  static assignmentQuestion(questionId, points) {
    return new AssignmentQuestion(
      questionId || TestData.randomString,
      createMock(ElementList.prototype),
      points || TestData.randomInt(0, 10)
    );
  }

  /**
   * @param [workQuestionId] {string}
   * @param [points] {string}
   * @param [startedAt] {string}
   * @return {AssignmentWorkQuestion}
   */
  static assignmentWorkQuestion(workQuestionId, points, startedAt) {
    return new AssignmentWorkQuestion(
      workQuestionId || TestData.randomString,
      createMock(ElementList.prototype),
      TestData.chatMessageList(),
      points || TestData.randomInt(0, 10),
      undefined,
      startedAt
    );
  }

  /**
   * @param work {AssignmentWork}
   * @param [notification] {AssignmentWorkNotification}
   * @returns {WorkQuestionSet}
   */
  static assignmentWorkQuestionSet(work, notification) {
    return new WorkQuestionSet(
      work,
      notification || TestData.assignmentWorkNotification()
    );
  }

  /**
   * @returns {AssignmentWorkNotification}
   */
  static assignmentWorkNotification() {
    const result = createMock(AssignmentWorkNotification.prototype);
    result.scoreUpdated = new JSEvent(result);
    return result;
  }

  /**
   * @param [chatMessages] {ChatMessage[]}
   * @param [chatMessageReads] {ChatMessageRead[]}
   * @return {ChatMessageList}
   */
  static chatMessageList(chatMessages = [], chatMessageReads = []) {
    let result = createMock(ChatMessageList.prototype);
    result.messages = new FirebaseCollection();
    chatMessages.forEach((chat) => result.messages._remoteAdd(chat));
    result.messagesRead = new FirebaseCollection();
    chatMessageReads.forEach((chatRead) => result.messagesRead._remoteAdd(chatRead));
    return result;
  }

  /**
   * @param [rosterId] {string}
   * @param [ownerId] {string}
   * @param [name] {string}
   * @param [allowPeerHelp] {boolean} indicates if students can help other student from roster
   * @param [allowNewMembers] {boolean} indicates if new students can join this roster
   * @param [allowMultiLogin] {boolean} indicates if students can login from multiple devices
   * @returns {Roster}
   */
  static roster(rosterId, ownerId, name, allowPeerHelp, allowNewMembers, allowMultiLogin, properties) {
    return new Roster(
      rosterId || TestData.randomString,
      ownerId || TestData.randomString,
      name || TestData.randomString,
      TestData.randomString,
      angular.isDefined(allowPeerHelp) ? allowPeerHelp : Math.random() < 0.5,
      angular.isDefined(allowNewMembers) ? allowNewMembers : Math.random() < 0.5,
      angular.isDefined(allowMultiLogin) ? allowMultiLogin : Math.random() < 0.5,
      properties || {}
    );
  }

  /**
   * Returns a Object representation of a Google Course
   *
   * @returns {{alternateLink: string, calendarId: string, courseGroupEmail: string, courseState: string, creationTime: string, descriptionHeading: string, enrollmentCode: string, guardiansEnabled: boolean, id: string, name: string, ownerId: string, section: string, teacherFolder: {id: string, title: string, alternateLink: string}, teacherGroupEmail: string, updateTime: string}}
   */
  static googleCourse() {
    return {
      alternateLink: 'http://classroom.google.com/c/MTE0NDY4NDA3NjBa',
      calendarId: 'classroom108340530029797342260@group.calendar.google.com',
      courseGroupEmail: 'Howdy_3_a42db900@classroom.google.com',
      courseState: 'ACTIVE',
      creationTime: '2018-02-06T19:45:31.566Z',
      descriptionHeading: '🤠🤠 Howdy 🤠🤠 3',
      enrollmentCode: 'sawamyk',
      guardiansEnabled: false,
      id: '11446840760',
      name: '🤠🤠 Howdy 🤠🤠',
      ownerId: '107054874837644759644',
      section: '3',
      teacherFolder:
        {
          id: '0B55R2l9QqgiNfkxOeERWN2NFYjRrZ25Tdml4UDVXTmF6OFduUzl6TldteDdzYkN2d2V5cUE',
          title: '🤠🤠 Howdy 🤠🤠 3',
          alternateLink: 'https://drive.google.com/drive/folders/0B55R2l9Qqg…NFYjRrZ25Tdml4UDVXTmF6OFduUzl6TldteDdzYkN2d2V5cUE'
        },
      teacherGroupEmail: 'Howdy_3_profesores_e19f13b0@classroom.google.com',
      updateTime: '2018-02-06T19:45:30.695Z'
    };
  }

  static googleStudents() {
    return {
      'students': [
        {
          'courseId': '10466681435',
          'userId': '114935563174311379235',
          'profile': {
            'id': '114935563174311379235',
            'name': {
              'givenName': 'Classkick',
              'familyName': 'Test',
              'fullName': 'Classkick Test'
            },
            'emailAddress': 'ckpdtest@gmail.com',
            'permissions': [
              {
                'permission': 'CREATE_COURSE'
              }
            ]
          }
        }
      ]
    };
  }

  /**
   * @param [assignmentId] {string}
   * @param [rosterId] {string}
   * @param [code] {string}
   * @param [showStudentScores] {boolean}
   * @param [lockStudentWork] {boolean}
   * @param [allowPdf] {boolean}
   * @returns {ClassCode}
   */
  static classCode(assignmentId, rosterId, code, showStudentScores, lockStudentWork, allowPdf) {
    return new ClassCode(
      code || TestData.randomString,
      rosterId || TestData.randomString,
      assignmentId || TestData.randomString,
      showStudentScores || true,
      lockStudentWork || false,
      allowPdf || false
    );
  }

  static assignmentRoster(assignmentId, rosterId, showStudentScores, lockStudentWork, hideStudentWork) {
    return new AssignmentRoster(
      assignmentId || TestData.randomString ,
      rosterId || TestData.randomString,
      showStudentScores || true,
      lockStudentWork || false,
      hideStudentWork || false
    );
  }

  /**
   * @param [userId] {string}
   * @param [username] {string}
   * @param [email] {string}
   * @param [firstName] {string}
   * @param [lastName] {string}
   * @param [displayName] {string}
   * @param [properties] {object}
   * @returns {User}
   */
  static user(userId, username, email, firstName, lastName, displayName, properties) {
    return new User(
      userId || TestData.randomString,
      username || TestData.randomString,
      email || TestData.randomString,
      firstName || TestData.randomString,
      lastName || TestData.randomString,
      displayName || TestData.randomString,
      moment().toISOString(),
      properties,
      undefined,
      undefined,
      undefined
    );
  }

  /**
   * @param [id] {string}
   * @param [username] {string}
   * @param [email] {string}
   * @param [firstName] {string}
   * @param [lastName] {string}
   * @param [displayName] {string}
   * @returns {SharedWorkUser}
   */
  static sharedWorkUser(id, username, email, firstName, lastName, displayName) {
    return new SharedWorkUser(
        id || TestData.randomString,
        username || TestData.randomString,
        email || TestData.randomString,
        firstName || TestData.randomString,
        lastName || TestData.randomString,
        displayName || TestData.randomString
    );
  }

  /**
   * @param [userId] {string}
   * @param [initialStatus] {object}
   * @returns {UserStatusNotification}
   */
  static userStatusNotification(userId, initialStatus) {
    const result = createMock(UserStatusNotification.prototype);
    result.userId = userId || TestData.randomString;
    result.status = {};
    result.updated = new JSEvent(result);
    result.loadOnce = () => initialStatus || {};
    return result;
  }

  /**
   * @param [userId] {string}
   * @returns {QuestionHelpers}
   */
  static questionHelpers(userId) {
    return new QuestionHelpers(
      TestData.helpersNotification(userId)
    );
  }

  /**
   * @param [userId] {string}
   * @returns {HelpersNotification}
   */
  static helpersNotification(userId) {
    const result = createMock(HelpersNotification.prototype);
    result.ids = [userId || TestData.randomString];
    result.helpers = new FirebaseCollection();
    return result;
  }

  /**
   * Generates a number of help requests from a dataset
   *
   * @param number {int}
   * @param assignments {Assignment[]}
   * @param rosters {Roster[]}
   * @param [users] {User[]}
   * @param [resolved] {boolean|{helperId: string}}
   * @returns {Array}
   */
  static helpRequests(number, assignments, rosters, users, resolved) {
    return TestData.sequence(number).map(() => {

      let isResolved = resolved;
      if (angular.isUndefined(isResolved)) {
        isResolved = Math.random() < 0.5;
      }

      const assignment = TestData.randomElement(assignments);
      const roster = TestData.randomElement(rosters);

      const helpee = users ? TestData.randomElement(users) : undefined;
      let helpeeId = helpee ? helpee.id : undefined;

      const helpRequestResolved = isResolved ? {} : false;
      if (isResolved) {
        const helper = users ? TestData.randomElement(users, helpee) : undefined;
        helpRequestResolved.helperId = (resolved && resolved.helperId) || (helper ? helper.id : undefined);
      }

      return TestData.helpRequest(
        assignment.id,
        TestData.randomElement(assignment.questions).id,
        roster.id,
        helpeeId,
        undefined,
        helpRequestResolved
      );
    });
  }

  /**
   *
   * @param [assignmentId] {string}
   * @param [questionId] {string}
   * @param [rosterId] {string}
   * @param [helpeeId] {string}
   * @param [helpType] {string}
   * @param [resolved] {boolean|{helperId: string, time: moment, helperRole: string}}
   * @returns {HelpRequest}
   */
  static helpRequest(assignmentId, questionId, rosterId, helpeeId, helpType, resolved) {

    let isResolved = resolved;
    if (angular.isUndefined(isResolved)) {
      isResolved = Math.random() < 0.5;
    }

    let resolvedTime = undefined;
    let helperId = undefined;
    let helperRole = undefined;
    if (resolved) {
      resolvedTime = resolved.time;
      helperId = resolved.helperId;
      helperRole = resolved.helperRole;
    }

    return new HelpRequest(
      TestData.randomString,
      helpeeId || TestData.randomString,
      rosterId || TestData.randomString,
      assignmentId || TestData.randomString,
      questionId || TestData.randomString,
      helpType || Math.random() < 0.5 ? HelpRequestTypes.HELP : HelpRequestTypes.CHECK,
      moment(),
      resolvedTime || isResolved ? moment() : undefined,
      helperId || isResolved ? TestData.randomString : undefined,
      helperRole || isResolved ? (Math.random() < 0.5 ? UserRoles.STUDENT : UserRoles.TEACHER) : undefined
    );
  }

  /**
   * @returns {RosterNotification}
   */
  static rosterNotification() {
    const result = createMock(RosterNotification.prototype);
    result.userAdded = new JSEvent(result);
    result.userRemoved = new JSEvent(result);
    result.metadata = new JSEvent(result);
    result.deleted = new JSEvent(result);
    return result;
  }

  /**
   * @return {AssignmentRosterNotification}
   */
  static assignmentRosterNotification() {
    const result = createMock(AssignmentRosterNotification.prototype);
    result.metadata = new JSEvent(this);
    return result;
  }

  /**
   * @return {AssignmentStatusNotification}
   */
  static assignmentNotification() {
    const result = createMock(AssignmentStatusNotification.prototype);
    result.questionAdded = new JSEvent(this);
    result.questionRemoved = new JSEvent(this);
    result.questionUpdated = new JSEvent(this);
    result.assignmentUpdated = new JSEvent(this);
    result.assignmentDeleted = new JSEvent(this);
    return result;
  }

  /**
   * @param helpRequests {HelpRequest[]}
   * @param [filterFunc] {function}
   * @param [indexFunc] {function}
   * @returns {HelpRequestSet}
   */
  static helpRequestSet(helpRequests, filterFunc, indexFunc) {
    return new HelpRequestSet(
      helpRequests || [],
      TestData.helpRequestNotification(),
      filterFunc,
      indexFunc
    );
  }

  /**
   * @returns {HelpRequestNotification}
   */
  static helpRequestNotification() {
    const result = createMock(HelpRequestNotification.prototype);
    result.created = new JSEvent(result);
    result.resolved = new JSEvent(result);
    result.canceled = new JSEvent(result);
    return result;
  }

  /**
   * @param [userStickerId] {string}
   * @param [text] {string}
   * @param [ownerId] {string}
   * @param [imageUrl] {string}
   * @param [tags] {object[]}
   * @return {UserSticker}
   */
  static userSticker(userStickerId, text, ownerId, imageUrl, tags) {
    return new UserSticker(
      userStickerId || TestData.randomString,
      text || TestData.randomString,
      ownerId || TestData.randomString,
      imageUrl || TestData.randomString,
      moment().toISOString(),
      tags || []
    );
  }

  /**
   * @param [assignment] {Assignment|AssignmentWork}
   * @param [user] {User}
   * @param [classCode] {ClassCode}
   * @return {AssignmentExport}
   */
  static assignmentExport(assignment, user, classCode) {
    return new AssignmentExport(
      assignment || TestData.assignmentWork(),
      user || TestData.user(),
      classCode || TestData.classCode(),
      TestData.sequence(TestData.randomInt(2, 5)).map(() => TestData.randomString)
    );
  }

  /**
   * @param [userMessageId] {string}
   * @param [senderId] {string}
   * @param [recipientId] {string}
   * @param [text] {string}
   * @param [actions] {string}
   * @param [createdDate] {string}
   * @param [seenDate] {string}
   * @param [deletedDate] {string}
   * @return {Message}
   */
  static userMessage(userMessageId, senderId, recipientId, text, actions, createdDate, seenDate, deletedDate) {
    return new Message(
      userMessageId || TestData.randomString,
      senderId || TestData.randomString,
      recipientId || TestData.randomString,
      text || TestData.randomString,
      actions || TestData.randomString,
      createdDate,
      seenDate,
      deletedDate
    );
  }

  /**
   * @param to {string}
   * @param params {object}
   * @return {CkRedirect}
   */
  static ckRedirect(to, params) {
    return new CkRedirect(to, params);
  }

  /**
   * @param count {number}
   * @return {Array}
   */
  static rawNewContractStudents(count = 5) {
    return TestData.sequence(count).map(() => {
      return TestData.rawNewContractStudent();
    });
  }

  /**
   * @param [id] {string}
   * @param [password] {string}
   * @param [firstName] {string}
   * @param [lastName] {string}
   * @return {{id: string, password: string, first_name: string, last_name: string}}
   */
  static rawNewContractStudent(id, password, firstName, lastName) {
    return {
      id: id || TestData.randomString,
      password: password || TestData.randomString,
      first_name: firstName || TestData.randomString,
      last_name: lastName || TestData.randomString
    };
  }

  /**
   * @param [id] {string}
   * @param [name] {string}
   * @param [plan] {string}
   * @param [created] {string}
   * @param [expires] {string}
   * @return {Contract}
   */
  static contract(id, name, plan, created, expires) {
    return new Contract(
      id || TestData.randomString,
      name || TestData.randomString,
      plan || ContractPlans.All[TestData.randomInt(0, 1)],
      created || undefined,
      expires || undefined
    );
  }

  /**
   * @param [id] {string}
   * @param [name] {string}
   * @return {Assignment}
   */
  static folder(id, name) {
    return TestData.assignment(id, 0, undefined, name);
  }

  static ckImageElement(id, metadata, point, size, scale, rotation, url) {
    return new CkImage(
      id || TestData.randomString,
      metadata || TestData.elementMetadata(),
      point || TestData.point(),
      size || TestData.size(),
      scale || 1,
      rotation || 0,
      url || TestData.randomString
    );
  }

  /**
   * @param [id] {string}
   * @param [metadata] {ElementMetadata}
   * @param [location] {Point}
   * @param [size] {Size}
   * @param [answers] {FillInTheBlankAnswer[]}
   * @return {FillInTheBlankParent}
   */
  static fillInTheBlankParent(id, metadata, location, size, answers) {
    return new FillInTheBlankParent(
      id || TestData.randomString,
      metadata || TestData.elementMetadata(),
      location || TestData.point(),
      size || TestData.size(),
      answers || TestData.sequence(3).map(() => TestData.fillInTheBlankAnswer())
    );
  }

  /**
   * @param [answer] {string}
   * @param [points] {number}
   * @param [selected] {undefined|boolean}
   * @return {FillInTheBlankAnswer}
   */
  static fillInTheBlankAnswer(answer, points, selected) {
    return new FillInTheBlankAnswer(
      answer || TestData.randomString,
      points || TestData.randomInt,
      selected
    );
  }

  static elementMetadata(ownerId, role, intent) {
    return new ElementMetadata(
      ownerId || TestData.randomString,
      role || UserRoles.TEACHER,
      intent || ElementIntents.CONTENT
    );
  }

  static point(x, y) {
    return new Point(
      x || TestData.randomInt(0, 1004),
      y || TestData.randomInt(0, 2008)
    );
  }

  static size(width, height) {
    return new Size(
      width || TestData.randomInt(0, 100),
      height || TestData.randomInt(0, 100)
    );
  }

  static subscription(id, ownerId, contractId, status, provider, active, created, updated, subscriptionPlan) {
    return new Subscription(
      id || TestData.randomString,
      ownerId || TestData.randomString,
      contractId || TestData.randomString,
      status || SubscriptionStatus.All[TestData.randomInt(0, SubscriptionStatus.All.length - 1)],
      provider || TestData.randomString,
      active || true,
      created || new Date().toISOString(),
      updated || new Date().toISOString(),
      subscriptionPlan || TestData.randomString,
      undefined
    );
  }

  static chatMessage(id, type, userId, displayName, text, timestamp) {
    return new ChatMessage(
      id || TestData.randomString,
      type || ChatMessageTypes.MESSAGE,
      userId || TestData.randomString,
      displayName || TestData.randomString,
      text || TestData.randomString,
      timestamp || moment().toISOString()
    );
  }

  static chatMessageRead(id, messageId, timestamp) {
    return new ChatMessageRead(
      id || TestData.randomString,
      messageId || TestData.randomString,
      timestamp || moment().toISOString()
    );
  }

  static copyQuestionsRequest(from_assignment_id, question_ids) {
    return new CopyQuestionsRequest(
      from_assignment_id || TestData.randomString,
      question_ids || [TestData.randomString]
    );
  }

  static get abTestSegment() {
    return new Map([['ab-test', 'A']]);
  }

    /**
   * @param [email] {string}
   * @param [firstName] {string}
   * @param [lastName] {string}
   * @returns {MdrTeacher}
   */
    static mdrTeacher(email, firstName, lastName) {
      return new MdrTeacher(
        email || TestData.randomString,
        firstName || TestData.randomString,
        lastName || TestData.randomString
      );
    }

}

'use strict';

/**
 * Redirects to your default location as a logged in user
 */
export default class AccountHomeController {
  /**
   * @param $timeout
   * @param $stateParams
   * @param AuthService {AuthService}
   * @param BreadcrumbService {BreadcrumbService}
   * @param BootstrapService {BootstrapService}
   * @param StudentCacheService {StudentCacheService}
   * @param ClassCodeService {ClassCodeService}
   * @ngInject
   */
  constructor($timeout, $state, $stateParams, $mdDialog, AuthService, BootstrapService, BreadcrumbService,
              StudentCacheService, ClassCodeService, StorageService) {

    if (BootstrapService.ready && AuthService.isLoggedIn) {

      $timeout()
        .then(() => {
          if (AuthService.authData.isTeacher) {
            if (StorageService.sharedWorkPath) {
              const path = StorageService.sharedWorkPath;
              StorageService.sharedWorkPath = '';
              StorageService.ckRedirect = null;
              $mdDialog.cancel();
              return BreadcrumbService.goUrl(path, true);
            } else {
              BreadcrumbService.go('root.account.nav.assignments', {}, true);
            }
          } else if (!AuthService.authData.isAnon && !$stateParams.classCode) {
            BreadcrumbService.go('root.account.nav.student-assignments-list', {}, true);
          } else if (!AuthService.authData.isAnon && $stateParams.classCode) {
            ClassCodeService.get($stateParams.classCode)
              .then((classCode) => {
                return StudentCacheService.getOrCreateWorkForSelf(classCode.assignmentId, classCode.rosterId);
              })
              .then((assignmentWork) => {
                BreadcrumbService.go('root.account.student-assignment-work', {
                  assignmentWorkId: assignmentWork.id,
                  questionId: assignmentWork.questionForIndex(0).id
                }, true);
              });
          } else if ($state.current.name === 'root.shared-work') {
            BreadcrumbService.go('root.shared-work', {
              sharedWorkId: $stateParams.sharedWorkId
            });
          } else {
            BreadcrumbService.go('root.login', {}, true);
          }
        });
    }
  }
}

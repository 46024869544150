
'use strict';

import StudentAccountHelpDialogController from '../student-account-help-dialog/student-account-help-dialog.controller';
import ShareWithStudentDialogTemplate from './share-with-student-dialog.html';
/**
 * Dialog to support sharing classcodes with students
 */
export default class ShareWithStudentDialogController {
  constructor($mdDialog, $location, $mdToast, clipboard, AnalyticsService, assignment, roster, classCode, pro) {
    'ngInject';

    this.$mdDialog = $mdDialog;
    this.$location = $location;
    this.$mdToast = $mdToast;
    this._clipboard = clipboard;

    /** @type {AnalyticsService} */
    this._analyticsService = AnalyticsService;

    /** @type {Assignment} */
    this.assignment = assignment;
    /** @type {Roster} */
    this.roster = roster;
    /** @type {ClassCode} */
    this.classCode = classCode;
    /** @type {string} */
    this.studentLink = classCode.studentUrl($location);
    /** @type {ProInfo} */
    this.pro = pro;

    this._showStudentAccountHelpDialog = StudentAccountHelpDialogController.show;
  }

  /**
   *
   * @param $mdDialog
   * @param assignment {Assignment}
   * @param roster {Roster}
   * @param classCode {ClassCode}
   * @param pro {ProInfo}
   * @returns {Promise.<string>}
   */
  static show($mdDialog, assignment, roster, classCode, pro) {
    return $mdDialog.show({
      controller: ShareWithStudentDialogController,
      template: ShareWithStudentDialogTemplate,
      controllerAs: 'ctrl',
      clickOutsideToClose: true,
      escapeToClose: true,
      locals: {
        assignment,
        roster,
        classCode,
        pro
      },
      focusOnOpen: false
    });
  }

  cancel() {
    this.$mdDialog.cancel();
  }

  copy() {
    this._clipboard.copyText(this.studentLink);

    angular.element('#link-input').select();

    this.$mdToast.show(
      this.$mdToast.simple()
        .textContent('Link copied to clipboard')
        .position('bottom right')
    );

    this._analyticsService.classCodeLinkCopied(this.roster.id, this.assignment.id);
  }

  openStudentAccountHelpDialog() {
    this._showStudentAccountHelpDialog(this.$mdDialog, this.pro);
  }

}

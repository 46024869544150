'use strict';

import moment from 'moment';

export class AssignmentTags {

  static get FOLDER() {
    return 'folder';
  }

  static get GRADES() {
    return 'grades';
  }

  static get SUBJECTS() {
    return 'subjects';
  }

  /** Rosters that are promoted for rosters to work on */
  static get PROMOTED() {
    return 'promoted';
  }
}

export class GradeRanges {

  static get PreKThroughSecondGrade() {
    return [
      'Pre-K',
      'Kindergarten',
      '1',
      '2'
    ];
  }

  static get ThirdThroughFifth() {
    return [
      '3',
      '4',
      '5'
    ];
  }

  static get SixthThroughEighth() {
    return [
      '6',
      '7',
      '8'
    ];
  }

  static get NinthThroughTwelfth() {
    return [
      '9',
      '10',
      '11',
      '12'
    ];
  }

  static get College() {
    return [
      'College'
    ];
  }

}

let assignmentGrades = [
  ...GradeRanges.PreKThroughSecondGrade,
  ...GradeRanges.ThirdThroughFifth,
  ...GradeRanges.SixthThroughEighth,
  ...GradeRanges.NinthThroughTwelfth,
  ...GradeRanges.College
];

let assignmentSubjects = [
  'Art',
  'Algebra',
  'Arithmetic',
  'Biology',
  'Chemistry',
  'Classroom Management',
  'Computer Science',
  'English Language Arts',
  'English Language Learners',
  'Foreign Language',
  'French',
  'Geometry',
  'German',
  'Geography',
  'History',
  'Interdisciplinary',
  'Math',
  'Music',
  'Physics',
  'Pre-Algebra',
  'Professional Development',
  'PBL',
  'Reading',
  'Science',
  'STEM',
  'Spanish',
  'Social Studies',
  'Templates',
  'Virtual/E-learning',
  'Writing',
  'Other'
];

export class AssignmentAccess {

  static get PUBLIC() {
    return 'public';
  }

}

/**
 * Contains the teacher's part of an assignment
 */
export default class Assignment {
  /**
   * @param id {string} the assignment id
   * @param name {string} the title of the assignment
   * @param ownerId {string} the id of the owner
   * @param questions {Array.<AssignmentQuestion>}
   * @param brief {string}
   * @param access {string}
   * @param lastModified {string}
   * @param rosters {Array.<string>}
   * @param isFolder {boolean}
   * @param folder {string|undefined} the id of the folder the assignment is in
   * @param grades {string[]}
   * @param subjects {string[]}
   * @param parentId {string}
   * @param parentModified {string}
   * @param ownerName {object}
   * @param promotedRosters {Array.<string>}
   * @param classCode {Object<ClassCode>}
   */
  constructor(
    id,
    name,
    ownerId,
    questions,
    brief,
    access,
    lastModified,
    rosters,
    isFolder,
    folder,
    grades,
    subjects,
    parentId,
    parentModified,
    ownerName,
    promotedRosters,
    classCode
  ) {
    this._id = id;
    this._name = name;
    this._ownerId = ownerId;
    this._questions = questions;
    this._brief = brief;
    this._access = access;
    this._lastModified = lastModified ? moment(lastModified) : undefined;
    this._rosters = rosters;
    this._isFolder = isFolder;
    this._folder = folder;
    this._grades = grades;
    this._subjects = subjects;
    this._parentId = parentId;
    this._parentModified = parentModified ? moment(parentModified) : undefined;
    this._ownerName = ownerName ? ownerName :  undefined;
    this._promotedRosters = promotedRosters;
    this._classCode = classCode ? classCode: undefined;
  }

  static get ASSIGNMENT_TITLE_MAX_LENGTH() {
    return 80;
  }

  static get Grades() {
    return assignmentGrades;
  }

  static get Subjects() {
    return assignmentSubjects;
  }

  /**
   * @returns {string} assignment id
   */
  get id() {
    return this._id;
  }

  /**
   * @returns {boolean}
   */
  get isWork() {
    return false;
  }

  /**
   * @returns {string} assignment name
   */
  get name() {
    return this._name;
  }

  /**
   * @param value {string}
   */
  set name(value) {
    this._name = value;
  }

  /**
   * @returns {string} id of owner
   */
  get ownerId() {
    return this._ownerId;
  }

  /**
   * @returns {Array.<AssignmentQuestion>}
     */
  get questions() {
    return this._questions;
  }

  /**
   * @returns {string}
     */
  get brief() {
    return this._brief;
  }

  /**
   * @param value {string}
   */
  set brief(value) {
    this._brief = value;
  }

  /**
   * @returns {string}
   */
  get access() {
    return this._access;
  }

  /**
   * @param value {string}
   */
  set access(value) {
    this._access = value;
  }

  /**
   * @returns {moment}
   */
  get lastModified() {
    return this._lastModified;
  }

  /**
   * formats the lastModified property as date
   *
   * @returns {string}
   */
  get lastModifiedAsDate() {
    return this._lastModified.format('MM/DD/YYYY');
  }

  /**
   * @returns {Array.<string>}
   */
  get rosters() {
    return this._rosters;
  }

  /**
   * returns {Array.<string>}
   */
  get promotedRosters() {
    return this._promotedRosters;
  }

  set promotedRosters(value) {
    this._promotedRosters = value;
  }

  /**
   * @return {boolean}
   */
  get isFolder() {
    return this._isFolder;
  }

  /**
   * @return {string|undefined}
   */
  get folder() {
    return this._folder;
  }

  /**
   * @param value {string|undefined}
   */
  set folder(value) {
    this._folder = value;
  }

  // get parentFolderId() {
  //   // not currently defined
  // }

  // Grades

  /**
   * @return {string[]}
   */
  get grades() {
    return this._grades;
  }

  /**
   * @param value {string[]}
   */
  set grades(value) {
    this._grades = value;
  }

  get displayGrades() {
    return this.grades && this.grades.join(', ');
  }

  /**
   * @return {string[]}
   */
  get subjects() {
    return this._subjects;
  }

  /**
   * @param value {string[]}
   */
  set subjects(value) {
    this._subjects = value;
  }

  get displaySubjects() {
    return this.subjects && this.subjects.join(', ');
  }

  /**
   * Stops all firebase listeners on this object
   */
  stop() {
    this._questions.forEach((q) => q.stop());
  }

  /**
   * @param questionId {string}
   * @returns {int}
   */
  indexForQuestionId(questionId) {
    if (!questionId) {
      return this._questions.length;
    }

    let index = this._questions.findIndex((question) => question.id === questionId);

    if (index > -1) {
      return index;
    }
    else {
      throw new Error(`question ${questionId} is not in assignment`);
    }
  }

  /**
   * @param index {int}
   * @returns {string}
   */
  questionIdForIndex(index) {
    let question = this._questions[index];
    return question && question.id;
  }

  /**
   * @param questionId {string}
   * @returns {AssignmentQuestion}
   */
  questionForId(questionId) {
    return this.questions[this.indexForQuestionId(questionId)];
  }

  /**
   * @param index {int}
   * @returns {AssignmentQuestion}
   */
  questionForIndex(index) {
    return this.questions[index];
  }

  /**
   * @param questionId {string}
   * @returns {AssignmentQuestion}
   */
  questionAfter(questionId) {
    return this.questions[this.indexForQuestionId(questionId) + 1];
  }

  /**
   * @param questionId {string}
   * @returns {string}
   */
  beforeQuestionId(questionId) {
    let questionAfter = this.questionAfter(questionId);
    return questionAfter && questionAfter.id;
  }

  /**
   * @param questionId {string}
   * @returns {number}
   */
  questionNumberForId(questionId) {
    let questionIndex = this.indexForQuestionId(questionId);
    return angular.isNumber(questionIndex) && questionIndex + 1;
  }

  /**
   * @returns {number}
   */
  totalPotentialPoints() {
    return this.questions.reduce((memo, current) => {
        return memo + current.points * 100;
    }, 0) / 100;
  }

  /**
   * @returns {string}
   */
  get parentId() {
    return this._parentId;
  }

  /**
   * @param value {string}
   */
  set parentId(value) {
    this._parentId = value;
  }

  /**
   * @returns {moment}
   */
  get parentModified() {
    return this._parentModified;
  }

  /**
   * @returns {object}
   */
  get ownerName(){
    return this._ownerName;
  }

  /**
   * @returns {string}
   */
  get displayName(){
    return this._ownerName && this._ownerName.display_name;
  }

  /**
   * @returns {string}
   */
  get firstName(){
    return this._ownerName && this._ownerName.first_name;
  }

  /**
   * @returns {string}
   */
  get lastName(){
    return this._ownerName && this._ownerName.last_name;
  }

  /**
   * @returns {Object<ClassCode>}
   */
  get classCode(){
    return this._classCode;
  }

  /**
   * @param value {Object<ClassCode>}
   */
  set classCode(value){
    this._classCode = value;
  }

  displayAuthorName(){
    if (this.displayName) {
      return this.displayName;
    } else if (this.firstName && this.lastName) {
      return `${this.firstName} ${this.lastName}`;
    } else if (this.lastName) {
      return this.lastName;
    } else if (this.firstName) {
      return this.firstName;
    } else {
      return '';
    }
  }

  /**
   * Merges the AssignmentQuestions from another assignment into
   * this Assignment, preserving any firebase data
   * cached therein. Optionally calls Stop on any questions
   * which exist in the target and not in the this object
   *
   * @param target {Assignment}
   * @param [stopOrphans] {boolean}
   * @returns {Assignment}
   */
  mergeFrom(target, stopOrphans) {
    if (target === this) {
      return this;
    }

    target.questions.forEach((question) => {
      const index = this.questions.findIndex((q) => q.id === question.id);
      if (index >= 0) {
        this.questions[index].mergeFrom(question);
      }
      else if (stopOrphans) {
        question.stop();
      }
    });

    return this;
  }

  /**
   * @param questionId {string}
   * @return {Promise.<number>}
   */
  initialElementCountForId(questionId) {
    let question = this.questionForId(questionId);
    return question.elementCount;
  }

  /**
   * @param questionIndex {number}
   * @return {Promise.<number>}
   */
  initialElementCountForIndex(questionIndex) {
    let questionId = this.questionIdForIndex(questionIndex);
    return this.initialElementCountForId(questionId);
  }

}

'use strict';

import NewContractStudent from '../../model/domain/new-contract-student';
import TestData from '../../model/util/test-data';
import Validation from '../../model/util/validation';
import MimeTypes from '../../model/domain/mime-types';
import Sorts from '../../model/domain/sorts';
import CreateContractStudentsDialogTemplate from './create-contract-students-dialog.html';

/**
 * Create new students for contract
 */
export default class CreateContractStudentsDialogController {

  /**
   * @ngInject
   */
  constructor($q, $mdDialog, $timeout, ContractService, AuthService, StaticContentService, CacheService, CsvService, UserService, contractId) {

    this.$q = $q;
    this.$mdDialog = $mdDialog;
    this.$timeout = $timeout;
    /** @type {ContractService} */
    this._contractService = ContractService;
    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {StaticContentService} */
    this._staticContentService = StaticContentService;
    /** @type {CacheService} */
    this._cacheService = CacheService;
    /** @type {CsvService} */
    this._csvService = CsvService;
    /** @type {UserService} */
    this._userService = UserService;

    this._state = this.Loading;
    this._stateStack = [];
    /** @type {Array.<NewContractStudent>} */
    this._newStudents = [];
    this._createdStudentIds = [];
    this._duplicateIds = [];
    this._validNewStudents = [];
    this._originalFile = undefined;

    this._newContractStudent = new NewContractStudent();
    this._newContractStudent.password = TestData.generateRandomString(6, `${TestData.LowercaseLetters}${TestData.Digits}`);
    this._oldContractStudent = undefined;
    this._isExistingStudent = false;
    this._contracts = [];
    this._contract = undefined;
    this._emailOrUsernameInputError = false;
    this._selectIsError = false;
    this._errorMessage = '';

    this._cacheService.getContracts().then((contracts) => {
      this._state = this.Select;
      this._contracts = contracts.filter((contract) => contract.isPro && !contract.isExpired);

      if (this.hasMultipleContracts && !contractId) {
        this._showContractSelector = true;
      }
      else if (!contractId) {
        this._contract = this._contracts[0];
        this._showContractSelector = false;
      }
      else {
        this._contract = this._contracts.find((contract) => contract.id === contractId);
        this._showContractSelector = false;
      }
    });
  }

  get state() {
    return this._state;
  }

  get Select() {
    return 'select';
  }

  get ManualAdd() {
    return 'manualAdd';
  }

  get ManualEdit() {
    return 'ManualEdit';
  }

  get ImportCsv() {
    return 'ImportCsv';
  }

  get Review() {
    return 'Review';
  }

  get Created() {
    return 'Created';
  }

  get Error() {
    return 'Error';
  }

  get Loading() {
    return 'Loading';
  }

  get TextCsv() {
    return MimeTypes.TEXT_CSV;
  }

  get UserFirstNameMaxSize() {
    return Validation.UserFirstNameMaxSize;
  }

  get UserLastNameMaxSize() {
    return Validation.UserLastNameMaxSize;
  }

  /**
   * @return {Contract[]}
   */
  get contracts() {
    return this._contracts;
  }

  /**
   * @return {Contract}
   */
  get contract() {
    return this._contract;
  }

  /**
   * @param value {Contract}
   */
  set contract(value) {
    this._contract = value;
  }

  /**
   * @return {boolean}
   */
  get hasMultipleContracts() {
    return this.contracts && this.contracts.length > 1;
  }

  /**
   * @return {boolean}
   */
  get showContractSelector() {
    return this._showContractSelector;
  }

  /**
   * @return {NewContractStudent}
   */
  get newContractStudent() {
    return this._newContractStudent;
  }

  /**
   * @return {NewContractStudent[]}
   */
  get newStudents() {
    return this._newStudents;
  }

  /**
   * @return {NewContractStudent[]}
   */
  get validNewStudents() {
    return this._validNewStudents;
  }

  /**
   * @param value {NewContractStudent[]}
   */
  set newStudents(value) {
    let teacherAccounts = value.filter((student) => student.valid && !student.isStudent).sort((studentA, studentB) => Sorts.NAME_ASC(studentA.lastName, studentB.lastName));
    let invalid = value.filter((student) => !student.valid).sort((studentA, studentB) => Sorts.NAME_ASC(studentA.lastName, studentB.lastName));
    let valid = value.filter((student) => student.valid && student.isStudent).sort((studentA, studentB) => Sorts.NAME_ASC(studentA.lastName, studentB.lastName));
    this._newStudents = [...teacherAccounts, ...invalid, ...valid];
  }

  /**
   * @return {File}
   */
  get originalFile() {
    return this._originalFile;
  }

  /**
   * @param value {File}
   */
  set originalFile(value) {
    this._originalFile = value;
  }

  /**
   * @return {string}
   */
  get errorMessage() {
    return this._errorMessage;
  }

  /**
   * @param state
   */
  go(state) {
    this._stateStack.push(this._state);
    this._state = state;
  }

  goToImport(scope) {
    if (!this._contract) {
      this._selectIsError = true;
      scope.selectForm.contracts.$setValidity('required', false);
      return;
    }
    this.go(this.ImportCsv);
  }

  goToManualAdd(scope) {
    if (!this._contract) {
      this._selectIsError = true;
      scope.selectForm.contracts.$setValidity('required', false);
      return;
    }
    this.go(this.ManualAdd);
  }

  resetSelectForm(scope) {
    this._selectIsError = false;
    scope.selectForm.contracts.$setValidity('required', true);
  }

  get selectIsError() {
    return this._selectIsError;
  }

  goBack() {
    if (this._state === this.Error){
      this.$mdDialog.hide();
    }
    if (this._oldContractStudent) {
      this.newStudents = [...this._newStudents, this._oldContractStudent];
      this._clearNewStudent();
    }
    else if (this._state === this.ManualAdd) {
      this._clearNewStudent();
      this._isExistingStudent = false;
    }

    if (this._stateStack.length > 0 && this._stateStack[this._stateStack.length - 1] !== this.Loading) {
      this._state = this._stateStack.pop();
    }
    else {
      this.$mdDialog.hide();
    }

    if (this._state === this.Select) {
      this._newStudents = [];
    }
  }

  goToTermsOfServicePage() {
    this._staticContentService.goToTermsOfServicePage();
  }

  goToPrivacyPolicyPage() {
    this._staticContentService.goToPrivacyPolicyPage();
  }

  get emailOrUsernameInputError() {
    return this._emailOrUsernameInputError;
  }

  emailOrUsernameUpdated(scope) {
    this.newContractStudent.isStudent = true;

    if (this.newContractStudent.id) {
      this._emailOrUsernameInputError = false;
      this._setValidity(scope, 'taken', true);
      this._setValidity(scope, 'email-format', true);
      this._setValidity(scope, 'email-length', true);
      this._setValidity(scope, 'on-contract', true);
      this._setValidity(scope, 'username-format', true);
      this._setValidity(scope, 'username-length', true);
      this._setValidity(scope, 'invalid-account', true);
      this._isExistingStudent = false;
    }
    else if (!scope.manualForm.emailOrUsernameInput.$pristine) {
      this._emailOrUsernameInputError = true;
    }
  }

  saveStudent(scope) {
    if (scope.manualForm.firstNameInput.$invalid || scope.manualForm.lastNameInput.$invalid) {
      return;
    }

    const idValidation = Validation.validateUsernameOrEmail(this.newContractStudent.id);
    if (idValidation) {
      this._emailOrUsernameInputError = true;
      this._setValidity(scope, idValidation, false);
      return;
    }

    if (this._isStudentOnList(this.newContractStudent.id)) {
      this._emailOrUsernameInputError = true;
      this._setValidity(scope, 'taken', false);
      return;
    }

    if (this._isExistingStudent === true) {
      this.newContractStudent.isIdValid = true;
      this.newContractStudent.isNotDuplicate = false;
      this.newContractStudent.isStudent = true;
      this.newStudents.push(this.newContractStudent);
      this._state = this.Review;
      if (this._isPreviousState(this.Review)) {
        this.goBack();
      } else {
        this._state = this.Review;
      }
      return;
    }

    this.isAlreadyInContract().then((result) => {
      if (result === true) {
        this._emailOrUsernameInputError = true;
        this._setValidity(scope, 'on-contract', false);
        return;
      } else {
        this._contractService.validateStudents(this._contract.id, [this.newContractStudent])
          .then((errors) => {
            if (errors[0] && errors[0].field === 'duplicate') {
              this._isExistingStudent = true;
            } else if (errors[0] && errors[0].field === 'not a student') {
              this._emailOrUsernameInputError = true;
              this.newContractStudent.isStudent = false;
              this._setValidity(scope, 'invalid-account', false);
            }
            else {
              this.newContractStudent.isIdValid = true;
              this.newContractStudent.isNotDuplicate = true;
              this.newContractStudent.isStudent = true;
              this.newStudents.push(this.newContractStudent);
              this._clearNewStudent(scope);
              this._state = this.Review;

              if (this._isPreviousState(this.Review)) {
                this.goBack();
              }
              else {
                this._state = this.Review;
              }
            }
          });
      }
    });


  }

  /**
   * @param state {string}
   * @return {boolean}
   */
  _isPreviousState(state) {
    return this._stateStack.length > 0 &&
           this._stateStack[this._stateStack.length - 1] === state;
  }

  _setValidity(scope, key, value) {
    scope.manualForm.emailOrUsernameInput.$setValidity(key, value);
  }

  /**
   * @param id {string}
   * @return {boolean}
   */
  _isStudentOnList(id) {
    return this._newStudents.some((x) => x.id === id);
  }

  _clearNewStudent(scope) {
    if (scope) {
      scope.manualForm.emailOrUsernameInput.$pristine = true;
    }
    this._newContractStudent = new NewContractStudent();
    this._newContractStudent.password = TestData.generateRandomString(6, `${TestData.LowercaseLetters}${TestData.Digits}`);
    this._oldContractStudent = undefined;
    this._emailOrUsernameInputError = false;
  }

  /**
   * @param newContractStudent {NewContractStudent}
   */
  editAccount(newContractStudent) {
    this._newStudents = this._newStudents.filter((existingStudent) => existingStudent.id !== newContractStudent.id);
    this._newContractStudent = newContractStudent;
    this._oldContractStudent = new NewContractStudent(
      newContractStudent.id,
      newContractStudent.firstName,
      newContractStudent.lastName,
      newContractStudent.password,
      newContractStudent.isIdValid,
      newContractStudent.isNotDuplicate
    );
    this.go(this.ManualEdit);
  }

  create() {
    let promises = [];
    this._state = this.Loading;
    this._validNewStudents = this._newStudents.filter((student) => student.validAndNew);
    let existingStudents = this._newStudents.filter((student) => !student.isNotDuplicate);
    existingStudents.forEach((student) => {
      promises.push(
        this._userService.findStudentAsContractTeacher(student.id, this._contract.id)
          .then((user) => {
            return this._contractService.addUser(this._contract.id, user.id);
          })
      );
    });
    this.$q.all(promises)
      .then(() => {
        return this._contractService.addStudents(this._contract.id, this._validNewStudents);
      })
      .then((createdStudents) => {
        this._createdStudentIds = createdStudents.map((createdStudent) => createdStudent.id);
        this.downloadCsv();
        this.go(this.Created);
      })
      .catch(() => {
        this._errorMessage = 'Oops! There was an error creating your students';
        this.go(this.Error);
      });
  }

  downloadCsv() {
    this._csvService.downloadCsv(this.validNewStudents);
  }

  downloadErrorCsv() {
    let errorStudents = this._newStudents.filter((student) => !student.valid || !student.isStudent);
    this._csvService.downloadCsv(errorStudents, true);
  }

  close() {
    let newUserIds = new Set(this._createdStudentIds);
    let duplicateUserIds = new Set(this._duplicateIds);
    let userIdsToAdd = new Set([...newUserIds, ...duplicateUserIds]);
    this.$mdDialog.hide(userIdsToAdd);
  }

  chooseFile() {
    angular.element('input.csv-input').click();
  }

  isAlreadyInContract() {
    return this._cacheService.getUsersForContract(this._contract.id).then((users) =>
      Array.from(users.values())
        .some((user) => user.isStudent &&
          user.email && user.email.toLowerCase() === this.newContractStudent.id.toLowerCase() ||
          user.username && user.username.toLowerCase() === this.newContractStudent.id.toLowerCase()));
  }

  fileChanged() {
    this._state = this.Loading;
    this._csvService.extractCSVData(this._originalFile)
      .then((students) => {
        this._originalFile = undefined;
        this.newStudents = students;

        return this._contractService.validateStudents(
            this._contract.id,
            students.filter((student) => student.valid)
          )
          .then((errors) => {
            let errorMap = new Map(errors.map((o) => [o.object_name, o.field]));

            let duplicateSet = this.$q.resolve(new Set());

            if (errorMap.size > 0) {
              // If there are errors, check for duplicate users on the contract
              duplicateSet = this._cacheService.getUsersForContract(this._contract.id, true)
                .then((users) => {

                  return Array.from(users.values())
                    .filter((user) => user.isStudent)
                    .reduce((accum, user) => {

                      if (user.username && errorMap.has(user.username)) {
                        accum.add(user.username);
                        this._duplicateIds.push(user.id);
                      }
                      if (user.email && errorMap.has(user.email)) {
                        accum.add(user.email);
                        this._duplicateIds.push(user.id);
                      }

                      return accum;
                    }, new Set());
                });
            }

            return this.$q.all({
              errorMap: errorMap,
              duplicates: duplicateSet
            });
          });
      })
      .then((result) => {

        // Set flags on new student records, and split them into those which
        // are duplicates on this contract and those which are not
        const partitions = this._newStudents.reduce((partition, student) => {

          if (result.errorMap.has(student.id)) {
            if (result.errorMap.get(student.id) === 'id') {
              student.isIdValid = false;
            } else if (result.errorMap.get(student.id) === 'not a student') {
              student.isStudent = false;
            } else if (result.errorMap.get(student.id) === 'duplicate') {
              student.isNotDuplicate = false;
            }
          }
          if (result.duplicates.has(student.id)) {
            partition.duplicates.push(student);
          }
          else {
            partition.newStudents.push(student);
          }
          return partition;
        }, {
          newStudents: [],
          duplicates: []
        });

        this.newStudents = partitions.newStudents;

        if (this.newStudents.length) {
          this.go(this.Review);
        } else {
          this.close();
        }
      })
      .catch((error) => {
        this._errorMessage = error && error.message || 'Oops! There was an error importing your CSV.';
        this.go(this.Error);
      });
  }

  get studentsAreInvalid() {
    return this._newStudents.length > 0 && this._newStudents.some((student) => !student.valid);
  }

  /**
   * @param student {NewContractStudent}
   */
  removeStudent(student) {
    this._newStudents = this._newStudents.filter((s) => s.id !== student.id);
  }

  disableContinueButton(){
    return this._newStudents.some((student) => !student.isStudent);
  }

  /**
   * @param $mdDialog
   * @param [contractId] {string}
   */
  static show($mdDialog, contractId) {
    return $mdDialog.show({
      controller: CreateContractStudentsDialogController,
      template: CreateContractStudentsDialogTemplate,
      controllerAs: 'ctrl',
      clickOutsideToClose: false,
      escapeToClose: true,
      locals: {
        contractId: contractId
      }
    });
  }
}

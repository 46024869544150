export function Environment() {
  angular.module('classkickApp')
    .constant('environment', {
      "serverUrlBase": process.env.SERVER_URL_BASE,
      "serverv2UrlBase": process.env.SERVER_V2_URL_BASE,
      "assetsBaseUrl": process.env.ASSETS_BASE_URL,
      "stripeKey": process.env.STRIPE_KEY,
      "debugLogging": process.env.DEBUG_LOGGING,
      "firebaseTraceLogging": process.env.FIREBASE_TRACE_LOGGING,
      "defaultFirebaseNotificationShards": process.env.DEFAULT_FIREBASE_NOTIFICATION_SHARDS,
      "retryMs": process.env.RETRY_MS,
      "accountInitTimeout": process.env.ACCOUNT_INIT_TIMEOUT,
      "delighted": process.env.DELIGHTED,
      "logRocket": process.env.LOGROCKET,
      "observability": {"enabled": process.env.OBSERVABILITY_ENABLED, "traceLogging": process.env.OBSERVABILITY_TRACE_LOGGING},
      "dataDog": {
        "enabled": process.env.DATA_DOG_ENABLED,
        "traceLogging": process.env.DATA_DOG_TRACE_LOGGING,
        "clientToken": process.env.DATA_DOG_CLIENT_TOKEN,
        "applicationId": process.env.DATA_DOG_APPLICATION_ID,
        "site": process.env.DATA_DOG_SITE,
        "service": process.env.DATA_DOG_SERVICE,
        "env": process.env.DATA_DOG_ENV,
        "sampleRate": parseInt(process.env.DATA_DOG_SAMPLE_RATE, 10),
        "trackInteractions": process.env.DATA_DOG_TRACK_INTERACTIONS
      },
      "clever": {"clientId": process.env.CLEVER_CLIENT_ID},
      "google": {
        "auth": {
          "apiKey": process.env.GOOGLE_AUTH_API_KEY,
          "clientId": process.env.GOOGLE_AUTH_CLIENT_ID,
        }, "stackdriverLogging": process.env.GOOGLE_STACKDRIVER_LOGGING
      },
      "googleAnalyticsIds": {
        "labs.classkick.com": process.env.GOOGLE_ANALYTICS_ID_LABS_CLASSKICK,
        "app.classkick.com": process.env.GOOGLE_ANALYTICS_ID_APP_CLASSKICK
      },
      "mixpanelId": process.env.MIXPANEL_ID ,
      "assignmentLibraryAccount": process.env.ASSIGNMENT_LIBRARY_ACCOUNT,
      "ipstack": process.env.IPSTACK,
    });
}

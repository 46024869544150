import RootTemplate from './controllers/root/root.html';
import ErrorTemplate from './controllers/error/404.html';
import OrderTemplate from './controllers/order/order.html';
import OrderDetailsTemplate from './controllers/order-detail/order-detail.html';
import SubmitOrderTemplate from './controllers/submit-order/submit-order.html';
import LoginTemplate from './controllers/login/login.html';
import AccountLoginTemplate from './controllers/account-login/account-login.html';
import CleverTemplate from './controllers/account-clever/account-clever.html';
import ForgotPasswordTemplate from './controllers/forgot-password/forgot-password.html';
import SignupChoiceTemplate from './controllers/signup-choice/signup-choice.html';
import SignupTemplate from './controllers/signup/signup.html';
import SignupNameTemplate from './controllers/signup-name/signup-name.html';
import SignupSchoolTemplate from './controllers/signup-school/signup-school.html';
import AnonAnonStudentAssignmentWorkTemplate from './controllers/anon-student-assignment-work/anon-student-assignment-work.html';
import AnonStudentAssignmentOverviewTemplate from './controllers/anon-student-assignment-overview/anon-student-assignment-overview.html';
import AnonStudentAssignmentFeedbackTemplate from './controllers/anon-student-assignment-feedback/anon-student-assignment-feedback.html';
import PasswordResetTemplate from './controllers/password-reset/password-reset.html';
import AccountTemplate from './controllers/account/account.html';
import AccountNavTemplate from './controllers/nav/nav.html';
import AssignmentListTemplate from './controllers/assignments-list/assignments-list.html';
import UserNotificationsList from './controllers/user-notifications-list/user-notifications-list.html';
import RosterListTemplate from './controllers/rosters-list/rosters-list.html';
import AdminTemplate from './controllers/admin/admin.html';
import RosterEditTemplate from './controllers/roster-edit/roster-edit.html';
import UserProfileTemplate from './controllers/user-profile/user-profile.html';
import ContractUsersListTemplate from './controllers/contract-users-list/contract-users-list.html';
import ContractUserEditTemplate from './controllers/edit-user/edit-user.html';
import BasicUserEditTemplate from './controllers/edit-user/edit-user.html';
import MetricsListTemplate from './controllers/metric-list/metric-list.html';
import StudentAssignmentsListTemplate from './controllers/student-assignments-list/student-assignments-list.html';
import AssignmentEditTemplate from './controllers/assignment-edit/assignment-edit.html';
import AssignmentEditQuestionTemplate from './controllers/assignment-edit-question/assignment-edit-question.html';
import AssignmentRostersTemplate from './controllers/assignment-rosters/assignment-rosters.html';
import SessionTemplate from './controllers/session/session.html';
import SessionWorkTemplate from './controllers/session-work/session-work.html';
import AssignmentTeacherFeedbackTemplate from './controllers/assignment-teacher-feedback/assignment-teacher-feedback.html';
import SessionWatchTemplate from './controllers/session-watch/session-watch.html';
import SessionQuestionTemplate from './controllers/session-question/session-question.html';
import StudentOverviewTemplate from './controllers/student-overview/student-overview.html';
import StudentAccountAssignmentOverviewTemplate from './controllers/student-account-assignment-overview/student-account-assignment-overview.html';
import StudentAccountAssignmentWorkTemplate from './controllers/student-account-assignment-work/student-account-assignment-work.html';
import StudentAccountAssignmentFeedbackTemplate from './controllers/student-account-assignment-feedback/student-account-assignment-feedback.html';
import PaymentTemplate from './controllers/payment/payment.html';
import HelpTemplate from './controllers/help/help.html';
import HelpCategoryTemplate from './controllers/help-category/help-category.html';
import HelpSectionTemplate from './controllers/help-section/help-section.html';
import HelpArticleTemplate from './controllers/help-article/help-article.html';
import HelpCoachesTemplate from './controllers/help-coaches/help-coaches.html';
import AssignmentLibraryTemplate from './controllers/assignment-library/assignment-library.html';
import PaymentSuccessControllerTemplate from './controllers/payment-success/payment-success.html';
import PublicAssignmentsListTemplate from './controllers/public-assignments-list/public-assignments-list.html';
import PublicAssignmentTemplate from './controllers/public-assignment/public-assignment.html';
import PublicAssignmentQuestionTemplate from './controllers/public-assignment-question/public-assignment-question.html';
import HiddenRostersListTemplate from './controllers/hidden-rosters-list/hidden-rosters-list.html';
import PreviewStudentAssignmentWorkTemplate
  from './controllers/preview-student-assignment-work/preview-student-assignment-work.html';
import PreviewStudentAssignmentOverviewTemplate
  from './controllers/preview-student-assignment-overview/preview-student-assignment-overview.html';
import SharedWorkTemplate from './controllers/shared-work/shared-work.html';

export function routerConfig ($stateProvider, $urlRouterProvider, $compileProvider, $locationProvider) {
  'ngInject';

  //  https://docs.angularjs.org/api/ng/provider/$locationProvider#html5Mode
  //  https://docs.angularjs.org/guide/$location
  $locationProvider.html5Mode(true);
  $locationProvider.hashPrefix('');

  $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|chrome-extension|localhost):/);

  $stateProvider
    .state('root', {
      abstract: true,
      template: RootTemplate,
      controller: 'RootController as ctrl'
    })

    .state('root.loading', {
      url: '',
      template: '',
      controller: 'LoadingController as ctrl'
    })

    .state('root.404', {
      template: ErrorTemplate,
      controller: 'ErrorController as ctrl',
      params: {
        name: '',
        errorMessage: ''
      }
    })

    .state('root.order', {
      url: '/order?{tab?}',
      template: OrderTemplate,
      controller: 'OrderController as ctrl',
      params: {
        tab: null
      }
    })

    .state('root.order-without-tab', {
      url: '/order/',
      template: OrderTemplate,
      controller: 'OrderController as ctrl',
      params: {
        tab: null
      }
    })

    .state('root.order-detail', {
      url: '/order/{orderId}?{lock_in_price?}&{source?}&{uid?}&{isIframe?}',
      template: OrderDetailsTemplate,
      controller: 'OrderDetailController as ctrl',
      params: {
        orderId: '',
        lock_in_price: null,
        source: null,
        uid: null,
        isIframe: null
      }
    })

    .state('root.purchase-order', {
      url: '/order/{orderId}/submit',
      template: SubmitOrderTemplate,
      controller: 'SubmitOrderController as ctrl',
      params: {
        orderId: '',
        isPayment: false
      }
    })

    .state('root.payment-order', {
      url: '/order/{orderId}/payment',
      template: SubmitOrderTemplate,
      controller: 'SubmitOrderController as ctrl',
      params: {
        orderId: '',
        isPayment: true
      }
    })

    .state('root.login', {
      url: '/login',
      template: LoginTemplate,
      controller: 'LoginController as ctrl',
      params: {
        classCode: '',
        name: '',
        errorMessage: ''
      }
    })

    .state('root.login-with-classcode', {
      url: '/{source:login|classcode}/{classCode:.{6}}',
      template: LoginTemplate,
      controller: 'LoginController as ctrl',
      params: {
        classCode: '',
        name: '',
        errorMessage: ''
      }
    })

    .state('root.account-login', {
      url: '/account/login',
      template: AccountLoginTemplate,
      params: {
        referral: '',
        classCode: ''
      }
    })

    .state('root.account-clever', {
      url: '/account/clever?{token?}&{state?}',
      template:  CleverTemplate,
      controller: 'AccountCleverController as ctrl',
      params: {
        token: '',
        state: ''
      }
    })

    .state('root.forgot-password', {
      url: '/forgot-password',
      template: ForgotPasswordTemplate,
      controller: 'ForgotPasswordController as ctrl'
    })

    .state('root.signup-choice', {
      url: '/signup/choice',
      template: SignupChoiceTemplate,
      controller: 'SignupChoiceController as ctrl'
    })

    .state('root.signup-teacher', {
      url: '/signup/teacher',
      template: SignupTemplate,
      params: {
        isTeacher: true,
        referral: ''
      }
    })

    .state('root.signup-student', {
      url: '/signup/student',
      template: SignupTemplate,
      params: {
        isStudent: true,
        classCode: null
      }
    })

    .state('root.signup-teacher-name', {
      url: '/signup/teacher/name',
      template: SignupNameTemplate,
      controller: 'SignupNameController as ctrl',
      params: {
        isTeacher: true,
        firstName: '',
        lastName: ''
      }
    })

    .state('root.signup-student-name', {
      url: '/signup/student/name',
      template: SignupNameTemplate,
      controller: 'SignupNameController as ctrl',
      params: {
        isStudent: true,
        firstName: '',
        lastName: ''
      }
    })

    .state('root.signup-school', {
      url: '/signup/teacher/school',
      template: SignupSchoolTemplate,
      controller: 'SignupSchoolController as ctrl'
    })

    .state('root.anon-student-assignment-work', {
      url: '/student/{classCode:.{6}}/{name:[^/]+}/{question:int}',
      template: AnonAnonStudentAssignmentWorkTemplate,
      controller: 'AnonStudentAssignmentWorkController as ctrl',
      params: {
        classCode: '',
        name: '',
        source: '',
        errorMessage: '',
        question: 1
      }
    })

    .state('root.anon-student-assignment-overview', {
      url: '/student/{classCode:.{6}}/{name:[^/]+}',
      template: AnonStudentAssignmentOverviewTemplate,
      controller: 'AnonStudentAssignmentOverviewController as ctrl',
      params: {
        classCode: '',
        name: ''
      }
    })

    .state('root.preview-student-assignment-overview', {
      url: '/preview/{assignmentId}/{classCode:.{6}}/{rosterId}/{name}',
      template: PreviewStudentAssignmentOverviewTemplate,
      controller: 'PreviewStudentAssignmentOverviewController as ctrl',
      params: {
        assignmentId: '',
        classCode: '',
        rosterId: '',
        name: '',
      }
    })

    .state('root.preview-student-assignment-work', {
      url: '/preview/{assignmentId}/{classCode:.{6}}/{rosterId}/{name}/{question:int}',
      template: PreviewStudentAssignmentWorkTemplate,
      controller: 'PreviewStudentAssignmentWorkController as ctrl',
      params: {
        assignmentId: '',
        classCode: '',
        rosterId: '',
        name: '',
        question: 1
      }
    })

    .state('root.anon-student-assignment-feedback', {
      url: '/student/{classCode:.{6}}/{name:[^/]+}/help',
      template: AnonStudentAssignmentFeedbackTemplate,
      controller: 'AnonStudentAssignmentFeedbackController as ctrl',
      params: {
        classCode: '',
        name: '',
        request: null
      }
    })

    .state('root.password-reset', {
      url: '/password-reset/{token}',
      template: PasswordResetTemplate,
      controller: 'PasswordResetController as ctrl'
    })

    .state('root.account', {
      abstract: true,
      url: '/account',
      template: AccountTemplate,
      controller: 'AccountController as ctrl',
      params: {
        authType: ''
      }
    })

    .state('root.account.home', {
      url: '',
      template: '',
      controller: 'AccountHomeController as ctrl',
      params: {
        classCode: ''
      }
    })

    .state('root.account.nav', {
      abstract: true,
      template: AccountNavTemplate,
      controller: 'NavController as ctrl'
    })

    .state('root.account.nav.assignments', {
      url: '/assignments',
      template: AssignmentListTemplate,
      controller: 'AssignmentsListController as ctrl',
      params: {
        source: ''
      }
    })

    .state('root.account.nav.folder', {
      url: '/assignments/folder/{folderId}',
      template: AssignmentListTemplate,
      controller: 'AssignmentsListController as ctrl',
      params: {
        folderId: ''
      }
    })

    .state('root.account.nav.notifications', {
      url: '/notifications',
      template: UserNotificationsList,
      controller: 'UserNotificationsListController as ctrl'
    })

    .state('root.account.nav.rosters', {
      url: '/rosters',
      template: RosterListTemplate,
      controller: 'RostersListController as ctrl'
    })

    .state('root.account.nav.roster', {
      url: '/rosters/{rosterId}',
      template: RosterEditTemplate,
      controller: 'RosterEditController as ctrl',
      params: {
        rosterId: '',
        isNew: false
      }
    })

    .state('root.account.nav.hidden-rosters', {
      url: '/rosters/hidden',
      template: HiddenRostersListTemplate,
      controller: 'HiddenRostersListController as ctrl'
    })

    .state('root.account.nav.admin', {
      url: '/admin',
      template: AdminTemplate,
      controller: 'AdminController as ctrl',
      params: {
        hideNavText: undefined
      }
    })

    .state('root.account.nav.profile', {
      url: '/profile?{tab?}',
      template: UserProfileTemplate,
      controller: 'UserProfileController as ctrl',
      reloadOnSearch: false,
      params: {
        tab: null
      }
    })

    .state('root.account.nav.contract-users-no-id', {
      url: '/contracts',
      template: ContractUsersListTemplate,
      controller: 'ContractUsersListController as ctrl',
      reloadOnSearch: false
    })

    .state('root.account.nav.contract-users', {
      url: '/contracts/{contractId}/users?{verifyUserId?}',
      template: ContractUsersListTemplate,
      controller: 'ContractUsersListController as ctrl',
      reloadOnSearch: false,
      params: {
        contractId: null,
        verifyUserId: null
      }
    })

    .state('root.account.nav.metrics', {
      url: '/metrics/{schoolId}',
      template: MetricsListTemplate,
      controller: 'MetricListController as ctrl',
      params: {
        schoolId: null
      }
    })

    .state('root.account.nav.metrics-no-contract-id', {
      url: '/metrics',
      template: MetricsListTemplate,
      controller: 'MetricListController as ctrl'
    })

    .state('root.account.nav.public-assignments-library', {
      url: '/assignments/library',
      template: AssignmentLibraryTemplate,
      controller: 'AssignmentLibraryController as ctrl'
    })

    .state('root.account.nav.public-assignments-list', {
      url: '/users/{userId}/shared',
      template: PublicAssignmentsListTemplate,
      controller: 'PublicAssignmentsListController as ctrl',
      params: {
        userId: ''
      }
    })

    .state('root.account.nav.public-assignment', {
      url: '/public/assignments/{assignmentId}',
      template: PublicAssignmentTemplate,
      controller: 'PublicAssignmentController as ctrl',
      params: {
        assignmentId: ''
      }
    })

    .state('root.account.nav.public-assignment-question', {
      url: '/public/assignments/{assignmentId}/question/{questionId}',
      template: PublicAssignmentQuestionTemplate,
      controller: 'PublicAssignmentQuestionController as ctrl',
      params: {
        assignmentId: '',
        questionId: ''
      }
    })

    .state('root.account.contract-user', {
      url: '/contracts/{contractId}/users/{userId}',
      template: ContractUserEditTemplate,
      controller: 'ContractUserEditController as ctrl',
      params: {
        contractId: null,
        userId: null
      }
    })

    .state('root.account.basic-user', {
      url: '/basic-user/{rosterId}/{userId}',
      template: BasicUserEditTemplate,
      controller: 'NonContractUserEditController as ctrl',
      params: {
        rosterId: null,
        userId: null
      }
    })

    .state('root.account.student-overview', {
      url: '/rosters/{rosterId}/user/{userId}',
      template: StudentOverviewTemplate,
      controller: 'StudentOverviewController as ctrl',
      params: {
        userId: null,
        rosterId: null
      }
    })

    .state('root.account.nav.student-assignments-list', {
      url: '/student-assignments',
      template: StudentAssignmentsListTemplate,
      controller: 'StudentAssignmentsListController as ctrl'
    })

    .state('root.account.assignment', {
      url: '/assignments/{assignmentId}',
      template: AssignmentEditTemplate,
      controller: 'AssignmentEditController as ctrl',
      params: {
        assignmentId: '',
        isNew: false,
        lastRosterId: ''
      }
    })

    .state('root.account.assignment-question', {
      url: '/assignments/{assignmentId}/question/{questionId}',
      template: AssignmentEditQuestionTemplate,
      controller: 'AssignmentEditQuestionController as ctrl',
      params: {
        assignmentId: '',
        questionId: ''
      }
    })

    .state('root.account.assignment-rosters', {
      url: '/assignments/{assignmentId}/rosters',
      template: AssignmentRostersTemplate,
      controller: 'AssignmentRostersController as ctrl',
      params: {
        assignmentId: '',
        lastRosterId: ''
      }
    })

    .state('root.account.session', {
      abstract: true,
      url: '/assignments/{assignmentId}/roster/{rosterId}',
      template: SessionTemplate,
      controller: 'SessionController as ctrl'
    })

    .state('root.account.session.work', {
      url: '/work',
      template: SessionWorkTemplate,
      controller: 'SessionWorkController as ctrl',
      params: {
        assignmentId: '',
        rosterId: '',
        sort: ''
      }
    })

    .state('root.account.session.work.feedback', {
      url: '/feedback/{assignmentWorkId}/{questionId}',
      template: AssignmentTeacherFeedbackTemplate,
      controller: 'AssignmentTeacherFeedbackController as ctrl',
      params: {
        assignmentId: '',
        assignmentWorkId: '',
        questionId: ''
      }
    })

    .state('root.account.session.work.roster', {
      url: '/feedback/{rosterId}/{assignmentWorkId}/{questionId}',
      template: AssignmentTeacherFeedbackTemplate,
      controller: 'AssignmentTeacherFeedbackController as ctrl',
      params: {
        assignmentId: '',
        assignmentWorkId: '',
        questionId: '',
        rosterId: ''
      }
    })

    .state('root.account.session.watch', {
      url: '/watch',
      template: SessionWatchTemplate,
      controller: 'SessionWatchController as ctrl',
      params: {
        assignmentId: '',
        rosterId: '',
        sort: ''
      }
    })

    .state('root.account.session.watch.feedback', {
      url: '/feedback/{assignmentWorkId}/{questionId}',
      template: AssignmentTeacherFeedbackTemplate,
      controller: 'AssignmentTeacherFeedbackController as ctrl',
      params: {
        assignmentId: '',
        assignmentWorkId: '',
        questionId: ''
      }
    })

    .state('root.account.session.question', {
      url: '/question/{questionNumber:int}',
      template: SessionQuestionTemplate,
      controller: 'SessionQuestionController as ctrl',
      params: {
        assignmentId: '',
        rosterId: '',
        questionNumber: 1,
        sort: ''
      }
    })

    .state('root.account.session.question.feedback', {
      url: '/feedback/{assignmentWorkId}/{questionId}',
      template: AssignmentTeacherFeedbackTemplate,
      controller: 'AssignmentTeacherFeedbackController as ctrl',
      params: {
        assignmentId: '',
        assignmentWorkId: '',
        questionId: ''
      }
    })

    .state('root.account.student-assignment-overview', {
      url: '/student-works/{assignmentWorkId}/overview',
      template: StudentAccountAssignmentOverviewTemplate,
      controller: 'StudentAccountAssignmentOverviewController as ctrl',
      params: {
        assignmentWorkId: ''
      }
    })

    .state('root.account.student-assignment-work', {
      url: '/student-works/{assignmentWorkId}/questions/{questionId}',
      template: StudentAccountAssignmentWorkTemplate,
      controller: 'StudentAccountAssignmentWorkController as ctrl',
      params: {
        assignmentWorkId: '',
        questionId: ''
      }
    })

    .state('root.account.student-assignment-feedback', {
      url: '/student-works/{assignmentWorkId}/help',
      template: StudentAccountAssignmentFeedbackTemplate,
      controller: 'StudentAccountAssignmentFeedbackController as ctrl',
      params: {
        assignmentWorkId: '',
        request: null
      }
    })

    .state('root.account.create-payment', {
      url: '/upgrade',
      template: PaymentTemplate,
      controller: 'PaymentController as ctrl'
    })

    .state('root.account.payment-success', {
      url: '/upgrade/success?{subscriptionId}',
      template: PaymentSuccessControllerTemplate,
      controller: 'PaymentSuccessController as ctrl',
      params: {
        subscriptionId: null
      }
    })

    .state('root.account.update-payment', {
      url: '/plans/{subscriptionPlan}/update-payment',
      template: PaymentTemplate,
      controller: 'PaymentController as ctrl',
      params: {
        subscriptionPlan: null
      }
    })

    .state('root.help', {
      url: '/help?{userEmail?}',
      template: HelpTemplate,
      controller: 'HelpController as ctrl',
      params: {
        userEmail: null
      }
    })

    .state('root.help-category', {
      url: '/help/categories/{categoryId}',
      template: HelpCategoryTemplate,
      controller: 'HelpCategoryController as ctrl',
      params: {
        categoryId: ''
      }
    })

    .state('root.help-section', {
      url: '/help/sections/{sectionId}',
      template: HelpSectionTemplate,
      controller: 'HelpSectionController as ctrl',
      params: {
        sectionId: ''
      }
    })

    .state('root.help-article', {
      url: '/help/articles/{articleId}',
      template: HelpArticleTemplate,
      controller: 'HelpArticleController as ctrl',
      params: {
        articleId: ''
      }
    })

    .state('root.help-coaches', {
      url: '/help/coaches/{sectionId}',
      template: HelpCoachesTemplate,
      controller: 'HelpCoachesController as ctrl',
      params: {
        sectionId: ''
      }
    })

    .state('root.shared-work', {
      url: '/shared-work/{sharedWorkId}',
      template: SharedWorkTemplate,
      controller: 'SharedWorkController as ctrl',
      params: {
        sharedWorkId: '',
        questionId: '1'
      }
    })

    /*TODO: Should be removed eventually as all future communications have
       the updated URL path for public assignments. This will allow users with
       the old url to access the public assignment controller which will redirect
       them to the new url
    */
    .state('root.public-assignment-original', {
      url: '/assignments/{assignmentId}',
      template: PublicAssignmentTemplate,
      controller: 'PublicAssignmentController as ctrl',
      params: {
        assignmentId: ''
      }
    })

    /*TODO: Should be removed eventually as all future communications have
       the updated URL path for assignment library. This will allow users with
       the old url to access the assignment library controller which will redirect
       them to the new url
     */
    .state('root.public-assignments-library-original', {
      url: '/assignments',
      template: AssignmentLibraryTemplate,
      controller: 'AssignmentLibraryController as ctrl'
    })

    .state('root.public-assignments-library', {
      url: '/assignments/library',
      template: AssignmentLibraryTemplate,
      controller: 'AssignmentLibraryController as ctrl'
    })

    .state('root.public-assignments-list', {
      url: '/users/{userId}/shared',
      template: PublicAssignmentsListTemplate,
      controller: 'PublicAssignmentsListController as ctrl',
      params: {
        userId: ''
      }
    })

    .state('root.public-assignment', {
      url: '/public/assignments/{assignmentId}',
      template: PublicAssignmentTemplate,
      controller: 'PublicAssignmentController as ctrl',
      params: {
        assignmentId: ''
      }
    })

    .state('root.public-assignment-question', {
      url: '/public/assignments/{assignmentId}/question/{questionId}',
      template: PublicAssignmentQuestionTemplate,
      controller: 'PublicAssignmentQuestionController as ctrl',
      params: {
        assignmentId: '',
        questionId: ''
      }
    })
  ;

  $urlRouterProvider
    .otherwise(($injector, $location) => {

      let path = $location.url();
      const state = $injector.get('$state');

      if (path === '' || path === '/') {
        state.go('root.loading');
        return;
      }

      if (path.startsWith('/teacher')) {
        path = path.replace(/^\/teacher/, '/account');
      }

      if (path.endsWith('/account/account')) {
        path = '/account/profile';
      }

      if (path.lastIndexOf('/') === path.length - 1) {
        path = path.slice(0, path.length - 1);
      }

      if (path.startsWith('/login-teacher')) {
        state.go('root.account-login');
        return;
      }

      if ($location.url() === path) {
        state.go('root.404', {errorMessage: 'Sorry, we couldn\'t find that page.'}, {reload: true});
      }

      return path;
    })
  ;

}
